<template>
  <div class="cont">
    <h3>Detail Site</h3>
    <div class="form_site">
    <b-form v-if="show">
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Nama</label>
        <div class="col-sm-10">
          <b-form-input
          id="input-2"
          v-model="this.form.name"
          required
          readonly
        ></b-form-input>
        </div>
      </div>

      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Deskripsi</label>
        <div class="col-sm-10">
          <b-form-textarea
          id="input-2"
          v-model="this.form.description"
          required
          readonly
        ></b-form-textarea>
        </div>
      </div>

      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Alamat</label>
        <div class="col-sm-10">
          <b-form-textarea
          id="input-2"
          v-model="this.form.address"
          required
          readonly
        ></b-form-textarea>
        </div>
      </div>

      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Kota/Kabupaten</label>
        <div class="col-sm-10">
          <b-form-input
          id="input-2"
          v-model="this.form.city"
          required
          readonly
        ></b-form-input>
        </div>
      </div>

      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Provinsi</label>
        <div class="col-sm-10">
          <b-form-input
          id="input-2"
          v-model="this.form.state"
          required
          readonly
        ></b-form-input>
        </div>
      </div>

        <div class="form-group row">
            <label for="staticEmail" class="col-sm-2 col-form-label">Kode Pos</label>
            <div class="col-sm-10">
            <b-form-input
            style="width:100px"
            :formatter="formatPostal"
            id="input-2"
            v-model="this.form.postal_code"
            required
            readonly
            ></b-form-input>
            </div>
        </div>

      <div class="d-flex justify-content-end" v-if="user.type==2">
        <b-button pill class="btn" size="sm" @click="toEdit()" variant="success">
          <b-icon icon="pencil-square"></b-icon>
        </b-button>
        <b-button pill class="btn" @click="onDelete()" size="sm" variant="danger">
          <b-icon icon="trash-fill"></b-icon>
        </b-button>
      </div>
    </b-form>
    </div>
  </div>
</template>

<script>
  import axios from "axios"
  import {mapGetters} from 'vuex'
  export default {
    data() {
      return {
        sites:{},
        form: {},
        show: true
      }
    },
    methods: {
      setSites(data){
        this.form = data
      },
      toEdit(){
        this.$router.push({ path : '/editsite/'+this.$route.params.id});
      },
      formatPostal(e){
        return String(e).substring(0,5);
      },
      goBack(){
        this.$router.go(-1)
      },
      onDelete(){
        axios.delete('v1/sites/' + this.$route.params.id )
        .then((response)=>{
            this.$router.push({ path : '/listsite/'});
        })
      },
    },
    mounted(){
      axios.get('v1/sites/'+this.$route.params.id)
      .then(response => {
          this.setSites(response.data['data']);
      });
    },
    computed: {
      ...mapGetters({
                isLoggedIn: 'isLoggedIn',
                user: 'user'})
    }
  }
</script>

<style scoped>
h3{
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.form-group{
  margin-top: 10px !important;
}

.d-flex{
  margin-top: 20px !important;
  margin-bottom: 30px !important;
}

.btn{
  margin-left: 10px !important;
  width: 70px !important;
}

.form_site{
  background-color: #ffff !important;
  padding: 35px !important;
  border-radius: 10px;
}

.cont h3 {
  font-weight: bold;
}

.cont {
  margin: 40px;
}


</style>



<script>
import Vue from 'vue'
import BootstrapSidebar from 'vue-bootstrap-sidebar'
import {mapGetters} from 'vuex'
// import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

export default Vue.extend({
  name: 'SideBarComponent',
  components: {
    BootstrapSidebar
  },
  data () {
    return {
      initialShow: true,
      header: "<img src='img/icons/logo-salman.png'>",
      links: [
        { name: 'Dashboard', href: { name: 'InfoDashboard' }, faIcon: ['fas', 'home'] },
        {
          name: 'Aset',
          faIcon: ['fas', 'cubes'],
          children: [

            {
              name: 'Kategori Aset',
              href: {
                name: 'Category'
              }
            },
            {
              name: 'Daftar Aset',
              href: {
                name: 'AssetList'
              }
            }
          ].concat(this.$store.getters.user.type == 0 ? [] :
            [{
              name: 'Tambah Aset',
              href: {
                name: 'AddAsset'
              }
            },
            ...this.$store.getters.user.type == 2 ?
            [{
              name: 'Import Aset',
              href: {
                name: 'ImportAsset'
              }
            }]:[]])
        },
        ...this.$store.getters.user.type == 2 ? 
        [{
          name: 'Akun',
          faIcon: ['fas', 'users'],
          children: [
            {
              name: 'Daftar Akun',
              href: {
                name: 'ListofAccount'
              }
            },
            {
              name: 'Tambah Akun',
              href: {
                name: 'CreateAccount'
              }
            }
          ]
        }] : [],
        {
          name: 'Sites',
          faIcon: ['fas', 'fa-map-pin'],
          children: [
            {
              name: 'Daftar Sites',
              href: {
                name: 'ListSite'
              }
            }
          ].concat(this.$store.getters.user.type != 2 ? [] :
            [{
              name: 'Tambah Sites',
              href: {
                name: 'CreateSite'
              }
            }])
        }
      ].concat(this.$store.getters.user.type == 2 ? 
      [{ 
        name: 'Request', 
        href: {
          name: 'RequestList' 
        }, 
        faIcon: ['fas', 'commenting'] 
      }]
       : [],
       { name: 'Reset Password', href: { name: 'ResetPassword' }, faIcon: ['fas', 'key'] },
       { name: 'Logout', href: { name: 'Logout' }, faIcon: ['fas', 'sign-out'] })
    }
  },
  methods: {
    onSidebarChanged () {
    },
    logOut(){
      this.$store.dispatch("logout").then(
        () => {
          this.$router.push("/login")
        }
      )
    }
  },
  computed:{
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
    })
  }
  // created(){
  //   this.noDataMessage = "<img src='images/logo-salman.png'>";
  // }
})
</script> 
 
<template>
  <div id="SideBarComponent">
    <BootstrapSidebar
      :initial-show="initialShow"
      :links="links"
      :header="header"
      :fa="true"
      @sidebarChanged="onSidebarChanged"
    >
      <template v-slot:navbar>
        <b-navbar
          id="mainNavbar"
          toggleable="lg"
          fixed="top"
        >
        <b-navbar-nav class="ms-auto">
          <b-nav-item id="nav_item_id">
              {{user.name}} <br>
              <p v-if="user.type==0">Massa Umum</p>
              <p v-if="user.type==1">Anggota Biasa</p>
              <p v-if="user.type==2">Anggota Utama</p>
          </b-nav-item>
        </b-navbar-nav>
        </b-navbar>

      </template>
 
      <template v-slot:content>
        <b-container style="margin-top: 56px">
          <router-view />
        </b-container>
      </template>
    </BootstrapSidebar>
  </div>
</template>
<style lang="scss">
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/vue-bootstrap-sidebar/src/scss/default-theme';
@import 'node_modules/bootstrap-vue/src/components/dropdown/_dropdown.scss';
@import '../scss/sidebar.scss';

#mainNavbar {
  padding: 0;
  padding-right: 10px;
}

#nav_item_id {
  font-size: 14px;
  font-weight: bold;
}
</style> 
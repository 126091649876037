<template>
    <div id="information-container">
        <div id="information-navbar">
            <ul class="list-group list-group-horizontal justify-content-center">
                <li><div id="detail" class="selected"><a @click="switchTab('detail')">Detail</a></div></li>
                <li v-if="is_depreciable == 1"><div id="penyusutan"><a @click="switchTab('penyusutan')">Penyusutan</a></div></li>
            </ul>
        </div>

        <div id="block-container">
            <!--Details-->
            <div v-if="currTabId === 'detail'">
                <!--Kategori == Tanah-->
                <div v-if="form.type=== 'Land'">
                    <h6>Lokasi</h6>
                    <!--Baris 1-->
                    <div class="row">
                    <!--Form Kiri-->
                    <div class="col-lg-6">

                        <!--Jalan-->
                        <div class="form-group row">
                        <label for="input-road" class="col-sm-3 col-form-label">Jalan</label>
                        <div class="col-sm-9">
                            <b-form-input
                                id="input-road"
                                v-model="form.street"
                                required
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.street" class="failed-text font-montserrat">{{error_message.street}}</p>
                        </div>
                        </div>

                        <!--Nomor Alamat-->
                        <div class="form-group row">
                        <label for="input-number" class="col-sm-3 col-form-label">Nomor Alamat</label>
                        <div class="col-sm-9">
                            <b-form-input
                                id="input-number"
                                v-model="form.number"
                                required
                                size="sm"
                            ></b-form-input>
                        </div>
                        </div>

                        <!--RT-->
                        <div class="form-group row">
                        <label for="input-rt" class="col-sm-3 col-form-label">RT</label>
                        <div class="col-sm-9">
                            <b-form-input
                                id="input-rt"
                                v-model="form.rt"
                                required
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.rt" class="failed-text font-montserrat">{{error_message.rt}}</p>
                        </div>
                        </div>

                        <!--RW-->
                        <div class="form-group row">
                        <label for="input-rw" class="col-sm-3 col-form-label">RW</label>
                        <div class="col-sm-9">
                            <b-form-input
                                id="input-rw"
                                v-model="form.rw"
                                required
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.rw" class="failed-text font-montserrat">{{error_message.rw}}</p>
                        </div>
                        </div>

                    </div>

                    <!--Form Kanan-->
                    <div class="col-lg-6">

                        <!--Desa-->
                        <div class="form-group row">
                        <label for="input-desa" class="col-sm-3 col-form-label">Desa / Kelurahan</label>
                        <div class="col-sm-9">
                            <b-form-input
                                id="input-desa"
                                v-model="form.village"
                                required
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.village" class="failed-text font-montserrat">{{error_message.village}}</p>
                        </div>
                        </div>

                        <!--Distrik?-->
                        <div class="form-group row">
                        <label for="input-distrik" class="col-sm-3 col-form-label">Kecamatan</label>
                        <div class="col-sm-9">
                            <b-form-input
                                id="input-distrik"
                                v-model="form.district"
                                required
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.district" class="failed-text font-montserrat">{{error_message.district}}</p>
                        </div>
                        </div>

                        <!--Kota-->
                        <div class="form-group row">
                        <label for="input-kota" class="col-sm-3 col-form-label">Kota / Kabupaten</label>
                        <div class="col-sm-9">
                            <b-form-input
                                id="input-city"
                                v-model="form.city"
                                required
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.city" class="failed-text font-montserrat">{{error_message.city}}</p>
                        </div>
                        </div>

                        <!--Provinsi-->
                        <div class="form-group row">
                        <label for="input-provinsi" class="col-sm-3 col-form-label">Provinsi</label>
                        <div class="col-sm-9">
                            <b-form-input
                                id="input-provinsi"
                                v-model="form.province"
                                required
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.province" class="failed-text font-montserrat">{{error_message.province}}</p>
                        </div>
                        </div>

                    </div>
                    </div>
                
                    <!--Baris 2-->
                    <h6>Lainnya</h6>
                    <div class="row">
                    <!--Kolom Kiri-->
                    <div class="col-lg-6">

                        <!--No. Persil-->
                        <div class="form-group row">
                        <label for="input-persil" class="col-sm-3 col-form-label">No. Persil</label>
                        <div class="col-sm-9">
                            <b-form-input
                                id="input-persil"
                                v-model="form.persil_number"
                                required
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.persil_number" class="failed-text font-montserrat">{{error_message.persil_number}}</p>
                        </div>
                        </div>

                        <!--No. Sertifikat-->
                        <div class="form-group row">
                        <label for="input-noCert" class="col-sm-3 col-form-label">No. Sertifikat</label>
                        <div class="col-sm-9">
                            <b-form-input
                                id="input-noCert"
                                v-model="form.certificate_number"
                                required
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.certificate_number" class="failed-text font-montserrat">{{error_message.certificate_number}}</p>
                        </div>
                        </div>
                    </div>

                    <!--Kolom Kanan-->
                    <div class="col-lg-6">

                        <!--NIB-->
                        <div class="form-group row">
                        <label for="input-nib" class="col-sm-3 col-form-label">NIB</label>
                        <div class="col-sm-9">
                            <b-form-input
                                id="input-nib"
                                v-model="form.nib_number"
                                required
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.nib_number" class="failed-text font-montserrat">{{error_message.nib_number}}</p>
                        </div>
                        </div>

                        <!--Luas-->
                        <div class="form-group row">
                        <label for="input-luas" class="col-sm-3 col-form-label">Luas</label>
                        <div class="col-sm-9">
                            <b-form-input
                                id="input-luas"
                                v-model="form.width"
                                required
                                type="number"
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.width" class="failed-text font-montserrat">{{error_message.width}}</p>
                        </div>
                        </div>
                    </div>
                    </div>

                    <!--Baris 3-->
                    <h6>Deskripsi</h6>
                    <b-form-textarea
                        id="textarea"
                        v-model="form.description"
                        placeholder="Enter something..."
                        rows="3"
                        max-rows="6"
                        size="sm"
                    ></b-form-textarea>
                    <p v-if="failed.description" class="failed-text font-montserrat">{{error_message.description}}</p>

                </div>

                <!--Kategori == Bangunan-->
                <div v-if="form.type=== 'Building'">
                    <!--Baris 1-->
                    <h6>Lokasi</h6>
                    <div class="row-xxl-6">

                        <!--Alamat-->
                        <div class="form-group row">
                        <label for="input-address" class="col-sm-2 col-form-label">Alamat</label>
                        <div class="col-sm-10">
                            <b-form-input
                                id="input-address"
                                v-model="form.address"
                                required
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.address" class="failed-text font-montserrat">{{error_message.address}}</p>
                        </div>
                        </div>

                    </div>
                    
                    <!--Baris 2-->
                    <h6>Lainnya</h6>
                    <div class="row">
                    <!--Kolom Kiri-->
                    <div class="col-lg-6">

                        <!--Jumlah Lantai-->
                        <div class="form-group row">
                        <label for="input-floor" class="col-sm-4 col-form-label">Jumlah Lantai</label>
                        <div class="col-sm-8">
                            <b-form-input
                                id="input-floor"
                                v-model="form.num_of_floors"
                                required
                                type="number"
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.num_of_floors" class="failed-text font-montserrat">{{error_message.num_of_floors}}</p>
                        </div>
                        </div>

                        <!--Luas Bangunan-->
                        <div class="form-group row">
                        <label for="input-luas" class="col-sm-4 col-form-label">Luas Bangunan</label>
                        <div class="col-sm-8">
                            <b-form-input
                                id="input-luas"
                                v-model="form.building_area"
                                required
                                type="number"
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.building_area" class="failed-text font-montserrat">{{error_message.building_area}}</p>
                        </div>
                        </div>

                        <!--Added Benefit Value-->
                        <div class="form-group row">
                        <label for="input-benefit-value" class="col-sm-4 col-form-label">Added Benefit Value</label>
                        <div class="col-sm-8">
                            <b-form-input
                                id="input-benefit-value"
                                v-model="form.added_benefit_value"
                                required
                                type="number"
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.added_benefit_value" class="failed-text font-montserrat">{{error_message.added_benefit_value}}</p>
                        </div>
                        </div>

                    </div>

                    <!--Kolom Kanan-->
                    <div class="col-lg-6">

                        <!--Tahun Dibangun-->
                        <div class="form-group row">
                        <label for="input-build_year" class="col-sm-4 col-form-label">Tahun Dibangun</label>
                        <div class="col-sm-8">
                            <b-form-input
                                id="input-build_year"
                                v-model="form.build_year"
                                required
                                type="number"
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.build_year" class="failed-text font-montserrat">{{error_message.build_year}}</p>
                        </div>
                        </div>

                        <!--Tahun Penggunaan-->
                        <div class="form-group row">
                        <label for="input-usage_year" class="col-sm-4 col-form-label">Tahun Penggunaan</label>
                        <div class="col-sm-8">
                            <b-form-input
                                id="input-usage_year"
                                v-model="form.usage_year"
                                required
                                type="number"
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.usage_year" class="failed-text font-montserrat">{{error_message.usage_year}}</p>
                        </div>
                        </div>

                    </div>
                    </div>

                </div>

                <!--Kategori == Kendaraan -->
                <div v-if="form.type=== 'Vehicle'">
                    <!--Baris 1-->
                    <h6>Spesifikasi</h6>
                    <div class="row">
                    <!--Kolom Kiri-->
                    <div class="col-lg-6">

                        <!--No. Mesin-->
                        <div class="form-group row">
                        <label for="input-engine" class="col-sm-4 col-form-label">Nomor Mesin</label>
                        <div class="col-sm-8">
                            <b-form-input
                                id="input-engine"
                                v-model="form.machine_number"
                                required
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.machine_number" class="failed-text font-montserrat">{{error_message.machine_number}}</p>
                        </div>
                        </div>

                        <!--No. Rangka-->
                        <div class="form-group row">
                        <label for="input-chassis" class="col-sm-4 col-form-label">Nomor Rangka</label>
                        <div class="col-sm-8">
                            <b-form-input
                                id="input-chassis"
                                v-model="form.body_number"
                                required
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.body_number" class="failed-text font-montserrat">{{error_message.body_number}}</p>
                        </div>
                        </div>

                        <!--Silinder-->
                        <div class="form-group row">
                        <label for="input-cylinder" class="col-sm-4 col-form-label">Isi Silinder</label>
                        <div class="col-sm-8">
                            <b-form-input
                                id="input-cylinder"
                                v-model="form.cc"
                                required
                                type="number"
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.cc" class="failed-text font-montserrat">{{error_message.cc}}</p>
                        </div>
                        </div>

                    </div>

                    <!--Kolom Kanan-->
                    <div class="col-lg-6">

                        <!--Tahun Pembuatan-->
                        <div class="form-group row">
                        <label for="input-year" class="col-sm-4 col-form-label">Tahun Pembuatan</label>
                        <div class="col-sm-8">
                            <b-form-input
                                id="input-year"
                                v-model="form.manufacture_year"
                                required
                                type="number"
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.manufacture_year" class="failed-text font-montserrat">{{error_message.manufacture_year}}</p>
                        </div>
                        </div>

                        <!--Brand-->
                        <div class="form-group row">
                        <label for="input-merk" class="col-sm-4 col-form-label">Jenis / Merk</label>
                        <div class="col-sm-8">
                            <b-form-input
                                id="input-merk"
                                v-model="form.brand"
                                required
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.brand" class="failed-text font-montserrat">{{error_message.brand}}</p>
                        </div>
                        </div>

                        <!--Jumlah Roda-->
                        <div class="form-group row">
                        <label for="input-tires" class="col-sm-4 col-form-label">Jumlah Roda</label>
                        <div class="col-sm-8">
                            <b-form-input
                                id="input-tires"
                                v-model="form.num_tires"
                                required
                                type="number"
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.num_tires" class="failed-text font-montserrat">{{error_message.num_tires}}</p>
                        </div>
                        </div>

                    </div>
                    </div>
                    
                    <!--Baris 2-->
                    <h6>Bukti Kepemilikan</h6>
                    <div class="row">
                    <!--Kolom Kiri-->
                    <div class="col-lg-6">

                        <!--BPKB-->
                        <div class="form-group row">
                        <label for="input-bkpb" class="col-sm-4 col-form-label">No. BPKB</label>
                        <div class="col-sm-8">
                            <b-form-input
                                id="input-bpkb"
                                v-model="form.bpkb_number"
                                required
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.bpkb_number" class="failed-text font-montserrat">{{error_message.bpkb_number}}</p>
                        </div>
                        </div>

                    </div>

                    <!--Kolom Kanan-->
                    <div class="col-lg-6">

                        <!--Police Number-->
                        <div class="form-group row">
                        <label for="input-police" class="col-sm-4 col-form-label">No. Polis</label>
                        <div class="col-sm-8">
                            <b-form-input
                                id="input-police"
                                v-model="form.police_number"
                                required
                                size="sm"
                            ></b-form-input>
                        <p v-if="failed.police_number" class="failed-text font-montserrat">{{error_message.police_number}}</p>
                        </div>
                        </div>
                    </div>
                    
                    </div>

                    <!--Baris 3-->
                    <h6>Lainnya</h6>
                    <div class="row">
                    <!--Kolom Kiri-->
                    <div class="col-lg-6">

                        <!--Sumber Pendanaan-->
                        <div class="form-group row">
                        <label for="input-source" class="col-sm-4 col-form-label">Sumber Pendanaan</label>
                        <div class="col-sm-8">
                            <b-form-input
                                id="input-source"
                                v-model="form.fund_source"
                                required
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.fund_source" class="failed-text font-montserrat">{{error_message.fund_source}}</p>
                        </div>
                        </div>

                    </div>

                    <!--Kolom Kanan-->
                    <div class="col-lg-6">

                        <!--Tanggung Jawab-->
                        <div class="form-group row">
                        <label for="input-charge" class="col-sm-4 col-form-label">Tanggung Jawab</label>
                        <div class="col-sm-8">
                            <b-form-input
                                id="input-charge"
                                v-model="form.in_charge"
                                required
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.in_charge" class="failed-text font-montserrat">{{error_message.in_charge}}</p>
                        </div>
                        </div>

                    </div>

                    </div>
                </div>

                <!--Kategori == Furnitur dan Elektronik-->
                <div v-if="form.type=== 'Furniture and Electronics'">
                    <!--Baris 1-->
                    <div class="row">
                    <!--Kolom Kiri-->
                        <div class="col-lg-6">

                            <!--Brand-->
                            <div class="form-group row">
                                <label for="input-merk" class="col-sm-3 col-form-label">Jenis / Merk</label>
                                <div class="col-sm-9">
                                    <b-form-input
                                        id="input-merk"
                                        v-model="form.brand"
                                        required
                                        size="sm"
                                    ></b-form-input>
                                    <p v-if="failed.brand" class="failed-text font-montserrat">{{error_message.brand}}</p>
                                </div>
                            </div>

                            <!--Kategori-->
                            <div class="form-group row">
                                <label for="input-category" class="col-sm-3 col-form-label">Kategori</label>
                                <div class="col-sm-9">
                                    <b-form-select
                                        id="input-category"
                                        v-model="form.category_id"
                                        required
                                        size="sm"
                                        style="width: 100%; height: 100%"
                                    >
                                    <b-form-select-option v-for="item in this.categories" :value="item.id">{{item.name}}</b-form-select-option>
                                    </b-form-select>
                                    <p v-if="failed.category_id" class="failed-text font-montserrat">{{error_message.category_id}}</p>
                                </div>
                            </div>
                        </div>

                        <!--Kolom Kanan-->
                        <div class="col-lg-6">

                            <!--Sumber-->
                            <div class="form-group row">
                                <label for="input-source" class="col-sm-3 col-form-label">Sumber</label>
                                <div class="col-sm-9">
                                    <b-form-input 
                                        id="input-source"
                                        v-model="form.source"
                                        required
                                        size="sm"
                                    ></b-form-input>
                                    <p v-if="failed.source" class="failed-text font-montserrat">{{error_message.source}}</p>
                                </div>
                            </div>

                            <!--Tanggung Jawab-->
                            <div class="form-group row">
                                <label for="input-charge" class="col-sm-3 col-form-label">Tanggung Jawab</label>
                                <div class="col-sm-9">
                                    <b-form-input
                                        id="input-charge"
                                        v-model="form.in_charge"
                                        required
                                        size="sm"
                                    ></b-form-input>
                                    <p v-if="failed.in_charge" class="failed-text font-montserrat">{{error_message.in_charge}}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div v-if="currTabId === 'penyusutan'">
                <div class="row">
                    <!--Depreciation Ratio-->
                    <div class="col-lg-6">
                        <div class="form-group row">
                        <label for="depreciate-ratio" class="col-sm-3 col-form-label">Rasio Depresiasi</label>
                        <div class="col-sm-9">
                        <b-form-input
                            id="depreciate-ratio"
                            v-model="form.depreciation_ratio"
                            required
                            type="number"
                            size="sm"
                        ></b-form-input>
                        <p v-if="failed.depreciation_ratio" class="failed-text font-montserrat">{{error_message.depreciation_ratio}}</p>
                        </div>
                        </div>
                    </div>

                    <!--Depreciation Start-->
                    <div class="col-lg-6">
                        <div class="form-group row">
                        <label for="depreciate-start" class="col-sm-3 col-form-label">Mulai Depresiasi</label>
                        <div class="col-sm-9">
                        <b-form-datepicker
                            id="depreciate-start"
                            v-model="form.depreciation_start_date"
                            size="sm"
                            required
                        ></b-form-datepicker>
                        <p v-if="failed.depreciation_start_date" class="failed-text font-montserrat">{{error_message.depreciation_start_date}}</p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        
    </div>    
</template>

<script>
export default {
    name: "AddAssetContainer",
    props: ['form', 'categories', 'is_depreciable', 'failed', 'error_message'],
    data() {
        return{
            currTabId: "detail"
        }
    },
    methods: {
        switchTab: function (targetTabId){
            if(this.currTabId != targetTabId){
                const currTab = document.getElementById(this.currTabId)
                if (currTab != null)
                    currTab.classList.remove("selected")
                document.getElementById(targetTabId).classList.add("selected")
                this.currTabId = targetTabId
            }
        }
    }
}
</script>

<style scoped>
    #information-container{
        margin-top: 2%;
    }

    #information-navbar{
        background: #121212;
        color: white;
        border-radius: 10px;
    }

    #block-container {
        background: white !important ;
        padding: 20px 40px 20px 40px !important;
        border-radius: 10px !important;
        justify-items: stretch;
        justify-content: center;    
    }
    
    ul {
        list-style-type: none;
        margin: 0;
        padding: 8px 0px 3px 0px;
        align-items: center;
    }
    li {
        padding-inline: 18%;
        font-size: 16px;
    }

    li a {
        display: block;
        color: white;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
    }
    .selected {
        border-bottom: 4px solid #F9D68C;
        padding: 0px 24px 0px 24px
    }
    h6 {
        padding-top: 8px
    }
    .form-group{
        padding-bottom: 8px
    }

    .failed-text{
        font-size: 14px;
        margin-top: 10px;
        color: red;
        text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    }

    .font-montserrat{
        font-family: "Montserrat";
    }

    p::first-letter{
        text-transform: capitalize;
    }
</style>


<template>
    <div id="information-container">
        <div id="information-navbar">
            <ul class="list-group list-group-horizontal justify-content-center">
                <li><div id="detail" class="selected"><a href="#detail" @click="switchTab('detail')">Detail</a></div></li>
                <li><div id="galeri"><a href="#galeri" @click="switchTab('galeri')">Galeri</a></div></li>
                <li v-if="form.is_depreciable"><div id="penyusutan"><a href="#penyusutan" @click="switchTab('penyusutan')">Penyusutan</a></div></li>
                <li><div id="dokumen"><a href="#dokumen" @click="switchTab('dokumen')">Dokumen</a></div></li>
            </ul>
        </div>

        <div id="block-container">
            <!--Details-->
            <div v-if="currTabId === 'detail'">
                <asset-details-container :form="form" :categories="categories" :isEdit="isEdit" :failed="failed" :error_message="error_message"/>
            </div>
            <div v-if="currTabId === 'galeri'">
                <div v-if="isEdit" class="button-padding">
                    <div class="d-flex justify-content-end">
                        <b-button pill class="btn" v-b-modal="'add-picture-modal'" variant="success">
                            <b-icon icon="plus"></b-icon> Unggah Gambar
                        </b-button>

                        <b-modal id="add-picture-modal"
                            hide-footer
                            title="Unggah Gambar"
                            header-bg-variant="dark"
                            header-text-variant="light"
                            >

                            <b-form @submit="UploadGallery" enctype="multipart/form-data" novalidate>
                            
                            <div class="form-group row">
                                <label for="gallery-file" class="col-sm-4 col-form-label">Pilih Gambar</label>
                                <div class="col-sm-8">
                                <input type="file" class="form-control" v-on:change="onGalleryChange" accept="image/*">
                                <p v-if="failed_gallery.file" class="failed-text font-montserrat">{{error_message_gallery.file}}</p>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="input-name" class="col-sm-4 col-form-label">Nama Gambar</label>
                                <div class="col-sm-8">
                                <b-form-input
                                    id="input-name"
                                    v-model="gallery_name"
                                    required
                                    size="sm"
                                ></b-form-input>
                                <p v-if="failed_gallery.name" class="failed-text font-montserrat">{{error_message_gallery.name}}</p>
                                </div>
                            </div>
    
                            <h6>Deskripsi</h6>
                            <b-form-textarea
                                id="textarea"
                                v-model="gallery_description"
                                placeholder="Enter something..."
                                rows="3"
                                max-rows="6"
                                size="sm"
                            ></b-form-textarea>
                            <p v-if="failed_gallery.description" class="failed-text font-montserrat">{{error_message_gallery.description}}</p>

                            <br>

                            <div class="d-flex justify-content-end">
                                <b-button pill class="btn" type="submit" v-b-modal="'add-gallery-modal'"  variant="success">
                                    <b-icon icon="plus"></b-icon> Unggah Gambar
                                </b-button>
                            </div>

                            </b-form>

                        </b-modal>
                    </div>
                </div>
                
                <b-row>
                <template v-if="gallery.length > 0" v-for="photo in gallery">
                    <b-col col lg="4">
                        <b-button class="custom-button" variant="outline-dark">
                            <div class="d-flex justify-content-end">
                                <b-button squared v-if="isEdit" class="btn" @click="DeleteGallery(photo.id)" size="sm" variant="danger">
                                    X
                                </b-button>
                            </div>

                            <b-img class="photo" :src="endpoint + photo.path" @click="DownloadGallery(photo)" v-b-popover.hover.top="'Unduh'"></b-img> 
                        </b-button>
                        <br></br>
                    </b-col>
                </template>
                <h5 v-else>TIDAK ADA GAMBAR</h5>
                </b-row>
            </div>

            <div v-if="currTabId === 'penyusutan'">
                <div class="row">
                    <!--Depreciation Ratio-->
                    <div class="col-lg-6">
                        <div class="form-group row">
                        <label for="depreciate-ratio" class="col-sm-3 col-form-label">Rasio Depresiasi</label>
                        <div class="col-sm-9">
                        <b-form-input
                            v-if="isEdit"
                            id="depreciate-ratio"
                            v-model="form.depreciation_ratio"
                            required
                            type="number"
                            size="sm"
                        ></b-form-input>
                        <b-form-input
                            v-else
                            id="depreciate-ratio"
                            v-model="form.depreciation_ratio"
                            required
                            disabled
                            type="number"
                            size="sm"
                        ></b-form-input>
                        <p v-if="failed.depreciation_ratio" class="failed-text font-montserrat">{{error_message.depreciation_ratio}}</p>
                        </div>
                        </div>
                    </div>

                    <!--Depreciation Start-->
                    <div class="col-lg-6">
                        <div class="form-group row">
                        <label for="depreciate-start" class="col-sm-3 col-form-label">Mulai Depresiasi</label>
                        <div class="col-sm-9">
                        <b-form-datepicker
                            v-if="isEdit"
                            id="depreciate-start"
                            v-model="form.depreciation_start_date"
                            size="sm"
                        ></b-form-datepicker>
                        <b-form-datepicker
                            v-else
                            id="depreciate-start"
                            v-model="form.depreciation_start_date"
                            size="sm"
                            disabled
                        ></b-form-datepicker>
                        <p v-if="failed.depreciation_start_date" class="failed-text font-montserrat">{{error_message.depreciation_start_date}}</p>
                        </div>
                        </div>
                    </div>
                </div>

                <b-table
                    id="ue_table"
                    :items="ue_items"
                    :fields="ue_fields"
                />
                <br>
                <line-chart :lineLabel="lineLabels" :lineChartData="lineData"/>
                <br>
                <b-table
                    id="yearly_cost_table"
                    :items="yearly_cost_items"
                    :fields="yearly_cost_fields"
                />
            </div>

            <div v-if="currTabId === 'dokumen'">
                <div class="button-padding" v-if="isEdit">
                    <div class="d-flex justify-content-end">
                        <b-button pill class="btn" v-b-modal="'add-document-modal'" variant="success">
                            <b-icon icon="plus"></b-icon> Unggah Dokumen
                        </b-button>

                        <b-modal id="add-document-modal"
                            hide-footer
                            title="Unggah Dokumen"
                            header-bg-variant="dark"
                            header-text-variant="light"
                            >

                            <b-form @submit="UploadDocument" enctype="multipart/form-data" novalidate>
                            
                            <div class="form-group row">
                                <label for="document-file" class="col-sm-4 col-form-label">Pilih File</label>
                                <div class="col-sm-8">
                                <input type="file" class="form-control" v-on:change="onDocumentChange" required>
                                <p v-if="failed_document.file" class="failed-text font-montserrat">{{error_message_document.file}}</p>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="input-name" class="col-sm-4 col-form-label">Nama Dokumen</label>
                                <div class="col-sm-8">
                                <b-form-input
                                    id="input-name"
                                    v-model="document_name"
                                    required
                                    size="sm"
                                ></b-form-input>
                                </div>
                                <p v-if="failed_document.name" class="failed-text font-montserrat">{{error_message_document.name}}</p>
                            </div>
    
                            <h6>Deskripsi</h6>
                            <b-form-textarea
                                id="textarea"
                                v-model="document_description"
                                placeholder="Enter something..."
                                rows="3"
                                max-rows="6"
                                size="sm"
                                required
                            ></b-form-textarea>
                            <p v-if="failed_document.description" class="failed-text font-montserrat">{{error_message_document.description}}</p>

                            <br>

                            <div class="d-flex justify-content-end">
                                <b-button pill class="btn" type="submit" v-b-modal="'add-document-modal'" variant="success">
                                    <b-icon icon="plus"></b-icon> Unggah Dokumen
                                </b-button>
                            </div>

                            </b-form>

                        </b-modal>
                    </div>
                </div>
                
                <b-table v-if="documents.length > 0"
                    id="document_table"
                    :items="documents"
                    :fields="document_fields"
                    :current-page="document_currentPage"
                    :per-page="perPage"
                >
                <template #cell(actions)="row">
                    <b-button-group>
                        <b-button @click="DownloadDocument(row.item)" variant="success">
                            <b-icon icon="download"></b-icon>
                        </b-button>
                        <b-button v-if="isEdit" @click="DeleteDocument(row.item.id)" variant="danger">
                            <b-icon icon="trash-fill"></b-icon>
                        </b-button>
                    </b-button-group>
                </template>
                </b-table>
                <h5 v-else>TIDAK ADA DOKUMEN</h5>
            </div>

        </div>

    <b-modal ref="success-upload-modal" class="font-montserrat" hide-footer hide-header no-close-on-backdrop no-close-on-esc hide-header-close>
        <div>
          <h3 class="font-montserrat">{{success_upload_message}}</h3>
        </div>
        <b-button class="mt-3 font-montserrat" variant="outline-success" block @click="closeModal">Oke</b-button>
    </b-modal>
        
    </div>    
</template>

<script>
import axios from "axios"
import LineChart from "../components/LineChart.vue"
import AssetDetailsContainer from "../components/AssetDetailsContainer.vue"
import {mapGetters} from 'vuex'

export default {
    name: "DetailContainer",
    props: ['form', 'documents', 'gallery', 'categories', 'isEdit', 'failed', 'error_message'],
    components: {
        LineChart,
        AssetDetailsContainer
    },
    data() {
        return {
            document_fields: [
                { key:'id', label:'ID Dokumen', sortable: true, sortDirection: 'desc', thClass: 'bg-dark text-white', tdClass: 'border'},
                { key:'name', label:'Nama Dokumen', sortable: true, sortDirection: 'desc', thClass: 'bg-dark text-white', tdClass: 'border'},
                { key:'description', label:'Deskripsi',  sortable: true, thClass: 'bg-dark text-white', tdClass: 'border'},
                { key:'type', label:'Tipe',  sortable: true, thClass: 'bg-dark text-white', tdClass: 'border'},
                { key:'actions', label:'Tindakan', thClass: 'bg-dark text-white', tdClass: 'border'}           
            ],
            ue_fields: [
                { key:'ue', label:'UE (Tahun)', thClass: 'bg-dark text-white', tdClass: 'border'},
                { key:'rate', label:'Tarif (%)', thClass: 'bg-dark text-white', tdClass: 'border'},
                { key:'curr_accumulation', label:'Akumulasi Saat Ini', thClass: 'bg-dark text-white', tdClass: 'border'},
            ],
            yearly_cost_fields: [
                { key:'year', label:'Tahun', thClass: 'bg-dark text-white', tdClass: 'border'},
                { key:'depreciation_value', label:'Penyusutan yang terjadi (Rp.)', thClass: 'bg-dark text-white', tdClass: 'border'},
                { key:'depreciated_value', label:'Nilai setelah penyusutan (Rp.)', thClass: 'bg-dark text-white', tdClass: 'border'},
            ],
            totalRows: 1,
            document_currentPage: 1,
            perPage: 5,
            num: 0,
            document_file: null,
            document_name: "",
            document_description: "",
            gallery_file: null,
            gallery_name: "",
            gallery_description: "",
            currTabId: "detail",
            lineLabels: [],
            lineData: [],
            ue_items: [],
            yearly_cost_items: [],
            endpoint: process.env.VUE_APP_ROOT_API,
            failed_document: {
                file: false,
                name: false,
                description: false,
            },
            error_message_document: {
                file: '',
                name: '',
                description: '',
            },
            failed_gallery: {
                file: false,
                name: false,
                description: false,
            },
            error_message_gallery: {
                file: '',
                name: '',
                description: '',
            },
            success_upload_message: '',
        }
    },
    methods: {
        switchTab: function (targetTabId){
            if(this.currTabId != targetTabId){
                const currTab = document.getElementById(this.currTabId)
                if (currTab != null)
                    currTab.classList.remove("selected")
                document.getElementById(targetTabId).classList.add("selected")
                this.currTabId = targetTabId
                if(targetTabId == "penyusutan"){
                    var new_cost = [];
                    Object.entries(this.form.asset_value_each_year).forEach(entry => {
                        const [key, value] = entry;
                        new_cost.push(
                            {
                                year: key,
                                depreciation_value: this.giveDots(this.form.depreciation_value),
                                depreciated_value: this.giveDots(value),
                            }
                        )
                    })
                    this.yearly_cost_items = new_cost;

                    var rawLineLabels = Object.keys(this.form.asset_value_each_year)
                    var rawLineData = Object.values(this.form.asset_value_each_year)

                    this.ue_items = [
                        {
                            ue: this.form.asset_life,
                            rate: this.form.depreciation_ratio,
                            curr_accumulation: this.giveDots(this.form.depreciation_value),
                        }
                    ]

                    // Hapus data yang tahunnya lebih dari 30 tahun sejak tahun ini
                    var currYear = 2025 // Default year 
                    currYear = new Date().getFullYear()
                    for(var i=0; i < rawLineLabels.length; i++){
                        if(parseInt(rawLineLabels[i]) >= (currYear + 30)){
                            rawLineData.splice(i, rawLineData.length - i)
                            rawLineLabels.splice(i, rawLineLabels.length - i)
                            break
                        }
                    }

                    // Hapus data yang tahunnya lebih dari 10 tahun sebelum tahun ini
                    for(var i=0; i < rawLineLabels.length; i++){
                        if(parseInt(rawLineLabels[i]) >= (currYear - 10)){
                            if(i == 0){
                                break
                            } else {
                                rawLineData.splice(0, i)
                                rawLineLabels.splice(0, i)
                                break
                            }
                        }
                    }

                    this.lineLabels = rawLineLabels
                    this.lineData = rawLineData
                }
            }
        },
        onDocumentChange(e){
            this.document_file = e.target.files[0];
        },
        onGalleryChange(e){
            this.gallery_file = e.target.files[0];
        },
        UploadDocument(e) {
            e.preventDefault();
            Object.keys(this.failed_document).forEach(k => this.failed_document[k] = false);
            Object.keys(this.error_message_document).forEach(k => this.error_message_document[k] = "");
            let currentObj = this;

            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }

            let formData = new FormData();
            formData.append('file', this.document_file);
            formData.append('name', this.document_name);
            formData.append('description', this.document_description);

            axios.post('v1/assets/' + this.form.id.toString() + '/documents', formData, config)
            .then(response => {
                currentObj.success = response.data.success;
                this.GetDocuments();
                this.$bvModal.hide('add-document-modal');
                this.showModal("dokumen")
            })
            .catch((error) => {
                const columnDocumentTranslated = {
                    "name": "nama dokumen",
                    "file": "file",
                    "description": "deskripsi",
                };

                Object.keys(this.failed_document).forEach( (key) => {
                    if(error.response.data.error.errors.hasOwnProperty(key)){
                        this.failed_document[key] = true;
                        this.error_message_document[key] = "Kolom " + columnDocumentTranslated[key] + " tidak boleh kosong";

                        if(error.response.data.error.errors[key].includes("The " + key + " must not be greater than 255 characters.")){
                            this.error_message_document[key] = columnDocumentTranslated[key] + " tidak boleh lebih dari 255 karakter";
                        }                        
                    }
                })
            });
        },
        UploadGallery(e) {
            e.preventDefault();
            Object.keys(this.failed_gallery).forEach(k => this.failed_gallery[k] = false);
            Object.keys(this.error_message_gallery).forEach(k => this.error_message_gallery[k] = "");
            let currentObj = this;

            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }

            let formData = new FormData();
            formData.append('file', this.gallery_file);
            formData.append('name', this.gallery_name);
            formData.append('description', this.gallery_description);

            axios.post('v1/assets/' + this.form.id.toString() + '/galleries', formData, config)
            .then(response => {
                currentObj.success = response.data.success;
                this.GetGallery();
                this.$bvModal.hide('add-picture-modal');
                this.showModal("gambar")
            })
            .catch((error) => {
                const columnGalleryTranslated = {
                    "name": "nama gambar",
                    "file": "file",
                    "description": "deskripsi",
                };

                Object.keys(this.failed_gallery).forEach( (key) => {
                    if(error.response.data.error.errors.hasOwnProperty(key)){
                        this.failed_gallery[key] = true;
                        this.error_message_gallery[key] = "Kolom " + columnGalleryTranslated[key] + " tidak boleh kosong";

                        if(error.response.data.error.errors[key].includes("The " + key + " must not be greater than 255 characters.")){
                            this.error_message_gallery[key] = columnGalleryTranslated[key] + " tidak boleh lebih dari 255 karakter";
                        }
                        
                        if(error.response.data.error.errors[key].includes("The file must be a file of type: jpeg, png, jpg, gif, svg.")){
                            this.error_message_gallery[key] = columnGalleryTranslated[key] + " hanya bisa berupa jpeg, png, jpg, gif, atau svg"
                        }
                    }
                })
            });
        },
        DownloadDocument(download_item) {
            var link = 'v1/assets/' + this.form.id.toString() + '/documents/' + download_item.id.toString() + '/download'
            var extension = download_item.path
            extension = extension.toString().split(".")
            extension = extension.pop()
            var name = download_item.name + "." + extension
            axios
            .get(link, { responseType: 'blob' })
            .then(response => {
                    const url = window.URL
                        .createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', name);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
            .catch (error => {console.log(error)})
        },
        DownloadGallery(download_item) {
            var link = 'v1/assets/' + this.form.id.toString() + '/galleries/' + download_item.id.toString() + '/download'
            var extension = download_item.path
            extension = extension.toString().split(".")
            extension = extension.pop()
            var name = download_item.name + "." + extension
            axios
            .get(link, { responseType: 'blob' })
            .then(response => {
                    const url = window.URL
                        .createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', name);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
            .catch (error => {console.log(error)})
        },
        GetDocuments() {
            axios
            .get('v1/assets/' + this.form.id.toString() + '/documents')
            .then(response => {
                this.documents = response.data.data;
                })
            .catch (error => {console.log(error)})
        },
        GetGallery() {
            axios
            .get('v1/assets/' + this.form.id.toString() + '/galleries')
            .then(response => {
                this.gallery = response.data.data;
                })
            .catch (error => {console.log(error)})
        },
        DeleteDocument(document_id) {
            var link = 'v1/assets/' + this.form.id.toString() + '/documents/' + document_id.toString()
            axios
            .delete(link)
            .then((response)=>{
                this.GetDocuments()
            })
        },
        DeleteGallery(document_id) {
            var link = 'v1/assets/' + this.form.id.toString() + '/galleries/' + document_id.toString()
            axios
            .delete(link)
            .then((response)=>{
                this.GetGallery()
            })
        },
        giveDots: function(num){
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        showModal(msg){
            if(this.user.type === 1){
                this.success_upload_message = "Request penambahan " + msg + " berhasil dibuat";
            } else if(this.user.type === 2){
                this.success_upload_message = msg[0].toUpperCase() + msg.substring(1) + " berhasil ditambahkan";
            }
            this.$refs["success-upload-modal"].show();
        },
        hideModal(){
          this.$refs["success-upload-modal"].hide();
        },
        closeModal(){
          this.hideModal();
        },
    },
    computed: {
        ...mapGetters({
            isLoggedIn: 'isLoggedIn',
            user: 'user',
        })
    }
}
</script>

<style scoped>
    #information-container{
        margin-top: 2%;
    }

    #information-navbar{
        background: #121212;
        color: white;
        border-radius: 10px;
    }

    #block-container {
        background: white !important ;
        padding: 20px 40px 20px 40px !important;
        border-radius: 10px !important;
        justify-items: stretch;
        justify-content: center;    
    }
    
    ul {
        list-style-type: none;
        margin: 0;
        padding: 8px 0px 3px 0px;
        align-items: center;
    }
    li {
        padding-inline: 9%;
        font-size: 16px;
    }

    li a {
        display: block;
        color: white;
        text-align: center;
        text-decoration: none;
    }
    .selected{
        border-bottom: 4px solid #F9D68C;
        padding: 0px 24px 0px 24px
    }
    h6 {
        padding-top: 8px
    }
    .form-group{
        padding-bottom: 8px
    }
    .button-padding {
        padding-bottom: 13px !important;
        padding-left: 0px !important;
    }
    .photo{
        width: 300px;
        height: 150px;
    }
    .custom-button {
        height: 200px;
    }
    .failed-text{
        font-size: 14px;
        margin-top: 10px;
        color: red;
        text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    }

    .font-montserrat{
        font-family: "Montserrat";
    }

    p::first-letter{
        text-transform: capitalize;
    }
    
    h5 {
        text-align: center;
    }
</style>


<template>
<body>
  <b-container>
    <b-row>
        <h3>Daftar Site</h3>
    </b-row>
    <b-row>
        <p>Daftar site yang terdaftar pada sistem informasi manajemen aset</p>
    </b-row>
    <div class="white-panel">
        <b-row>
            <div class="button-padding">
            <b-col lg="auto">
                <b-button pill to="/filtersite" variant="success" size="sm">
                    <b-icon icon="search"></b-icon>
                    Filter Data
                </b-button>
            </b-col>
            </div>
        </b-row>
        <b-row>
            <b-table
                id="site_table"
                :items="sites"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
            >
                <template #cell(actions)="row">
                    <b-button-group>
                        <b-button @click="toDetail(row.item.id)" variant="warning">
                            <b-icon icon="eye-fill" variant="light"></b-icon>
                        </b-button>
                        <b-button v-if="user.type === 2" @click="toEdit(row.item.id)" variant="success">
                            <b-icon icon="pencil-square"></b-icon>
                        </b-button>
                        <b-button v-if="user.type === 2" @click="onDelete(row.item.id)" variant="danger">
                            <b-icon icon="trash-fill"></b-icon>
                        </b-button>
                    </b-button-group>
                </template>
            </b-table>
        </b-row>
        <div class="overflow-auto">
            <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="site_table"
            ></b-pagination>
        </div>
    </div>
  </b-container>
</body>
</template>

<script>
    import axios from "axios"
    import {mapGetters} from 'vuex'
    export default {
        props: ['search', 'sortOn', 'sortFrom', 'amount'],
        data() {
            return {
                fields: [
                { key:'id', label:'ID', sortable: true, sortDirection: 'desc', thClass: 'bg-dark text-white', tdClass: 'border'},
                { key:'name', label:'Nama Site',  sortable: true, thClass: 'bg-dark text-white', tdClass: 'border'},
                { key:'address', label:'Alamat Site',  sortable: true, thClass: 'bg-dark text-white', tdClass: 'border'},
                { key:'city', label:'Kota Site',  sortable: true, thClass: 'bg-dark text-white', tdClass: 'border'},
                { key:'state', label:'Provinsi Site',  sortable: true, thClass: 'bg-dark text-white', tdClass: 'border'},
                { key:'postal_code', label:'Kode Pos',  sortable: true, thClass: 'bg-dark text-white', tdClass: 'border'},
                { key:'actions', label:'Tindakan', thClass: 'bg-dark text-white', tdClass: 'border', class: 'text-center'}
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                sortBy: 'id',
                sortDesc: false,
                sites: [] 
            }
        },
        mounted() {
            this.GetSites();

            if (this.amount) {
                this.perPage = this.amount
            }
            if (this.sortOn) {
                this.sortBy = this.sortOn
                if (this.sortFrom) {
                    this.sortDesc = this.sortFrom.match('desc')
                }
            }
        },
        computed: {
            rows() {
                return this.sites.length
            },
            ...mapGetters({
                isLoggedIn: 'isLoggedIn',
                user: 'user'})
        },
        methods: {
            GetSites(){
                var link = 'v1/sites?eagerLoad=true&itemsPerPage=9999'
                if (this.search) {
                    link = link + "&query=" + this.search.toString()
                }
                axios.get(link)
                .then(response => {this.sites = response.data.data});
            },
            onDelete(id){
                axios.delete('v1/sites/' + id)
                .then((response)=>{
                    this.GetSites();
                })
            },
            toDetail(id){
                this.$router.push({ path : '/detailsite/'+id });
            },
            toEdit(id){
                this.$router.push({ path : '/editsite/'+id });
            }
        }

    }
</script>

<style scoped>
p {
  font-size: 14xp;
  margin-bottom: 10px;
  font: "Montserrat";
}
.d-flex{
  margin-top: 20px;
  margin-bottom: 30px;
}
h3 {
  font: "Montserrat";
  font-weight: bold;
}
.btn{
  margin-left: 10px;
}
.form-group{
  margin-top: 10px;
}

#detail {
  text-align: center;
  padding: 5px;
}

 body {
     display: flex !important ;
     flex-direction: column !important ;
     background: #F1F1F1 !important ;
 }
 .white-panel {
     margin-top: 20px;
     background: white !important ;
     padding: 20px 40px 20px 40px !important;
     border-radius: 10px !important;
 }
 .button-padding {
   padding-bottom: 13px !important;
   padding-left: 0px !important;
}
.container {
  font-size: 14px !important
}
</style>
<template>
  <div class="container">
    <h3>Forgot Password</h3>
    <div class="form_account">
    <b-form v-if="show">
        <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Email</label>
        <div class="col-sm-10">
          <b-form-input
          id="input-email"
          v-model="form.email"
          type="text"
          required
        ></b-form-input>
        </div>
      </div>

      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Password Baru</label>
        <div class="col-sm-10">
          <b-form-input
          id="input-1"
          v-model="form.password"
          type="password"
          required
        ></b-form-input>
        <div class="passwordView" v-on:click="changePasswordView(1)">
          <b-icon v-if="password_hidden" icon="eye-fill"></b-icon>
          <b-icon v-if="!password_hidden" icon="eye-slash-fill"></b-icon>
        </div>
        <p v-if="failed.password" class="failed-text font-montserrat">{{error_message.password}}</p>
        </div>
      </div>

      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Konfirmasi Password Baru</label>
        <div class="col-sm-10">
          <b-form-input
          id="input-2"
          v-model="form.password_confirmation"
          type="password"
          required
        ></b-form-input>
        <div class="passwordView" v-on:click="changePasswordView(2)">
          <b-icon v-if="password_confirmation_hidden" icon="eye-fill"></b-icon>
          <b-icon v-if="!password_confirmation_hidden" icon="eye-slash-fill"></b-icon>
        </div>
        <p v-if="failed.password_confirmation" class="failed-text font-montserrat">{{error_message.password_confirmation}}</p>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <b-button pill class="btn" @click="save()" variant="success">
            <b-icon icon="check2"></b-icon>
          </b-button>
          <b-button pill class="btn" @click="goBack()" variant="danger">
            <b-icon icon="arrow-left"></b-icon>
          </b-button>
      </div>
    </b-form>
      <b-modal ref="success-modal" class="font-montserrat" hide-footer hide-header no-close-on-backdrop no-close-on-esc hide-header-close>
        <div>
          <h3 class="font-montserrat">Password berhasil diganti</h3>
        </div>
        <b-button class="mt-3 font-montserrat" variant="outline-success" block @click="closeModal">Oke</b-button>
      </b-modal>
    </div>
    <!-- <b-card class="mt-3" header="Form Data Result">
      <pre class="m-0">{{ form }}</pre>
    </b-card> -->
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import axios from 'axios';
  export default {
    data() {
      return {
        form: {
            token: this.$route.query.token,
            email:'',
            password:'',
            password_confirmation:''
        },
        show: true,
        failed: {
          password: false,
          password_confirmation: false,
        },
        error_message: {
          password: "",
          password_confirmation: "",
        },
        password_hidden: true,
        password_confirmation_hidden: true,
      }
    },
    methods: {
      save(){
        this.failed.password = false;
        this.failed.password_confirmation = false;
        this.error_message.password = "";
        this.error_message.password_confirmation = "";
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        axios.post('reset-password',this.form,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-type': 'application/json',
            }
        })
        .then((response)=>{
          if(response.status === 200) {
              this.showModal();
          }
          
        }).catch((error)=>{
          if(error.response.data.error.errors.password.includes("The password field is required.")){
            this.failed.password = true;
            this.error_message.password = "Password tidak boleh kosong";
          }

          if(error.response.data.error.errors.password.includes("The password must be at least 6 characters.")){
            this.failed.password = true;
            this.error_message.password = "Password harus terdiri dari minimal enam karakter";
          }

          if(error.response.data.error.errors.password.includes("The password confirmation does not match.")){
            this.failed.password_confirmation = true;
            this.error_message.password_confirmation = "Konfirmasi password berbeda dengan masukan password";
          }
        })
      },
      goBack(){
        this.$router.go(-1)
      },
      showModal(){
        this.$refs["success-modal"].show();
      },
      hideModal(){
        this.$refs["success-modal"].hide();
      },
      closeModal(){
        this.hideModal();
        this.$router.push({ path : '/dashboard' });
      },
      changePasswordView(num){
          if(num === 1){
            this.password_hidden = !this.password_hidden;
          } else {
            this.password_confirmation_hidden = !this.password_confirmation_hidden;
          }
          const password = num===1 ? document.querySelector('#input-1') :  document.querySelector('#input-2');
          const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
          password.setAttribute('type', type);
      }
    },
    computed:{
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
    })
  }
  }
</script>

<style scoped>
h3{
  padding-top: 30px !important;
  margin-bottom: 30px !important;
}

.form-group{
  margin-top: 10px !important;
}

.d-flex{
  margin-top: 20px !important;
  margin-bottom: 30px !important;
}

.btn{
  margin-left: 10px !important;
  width: 70px !important;
}

.form_account{
  background-color: #ffff !important;
  padding: 35px !important;
  border-radius: 10px !important;
}

.cont h3 {
  font-weight: bold !important;
}

.cont {
  margin: 40px !important;
}

label {
  font-size: 14px;
}
.failed-text{
  font-size: 14px;
  margin-top: 10px;
  color: red;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

.passwordView{
  position: relative;
  margin-top: -30px; 
  margin-left: 95%;
  cursor: pointer;
}

.font-montserrat{
  font-family: "Montserrat";
}


</style>
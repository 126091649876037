<template>
    <div>
        <div id="left-side">
            <div id="left-side-title"><b><h1>Sistem Informasi Manajemen Aset YPM Salman ITB</h1></b></div>
            <img src="../assets/left-side-photos.png" id="left-side-photos"/>
        </div>
        <div id="login-form">
            <div id="logo-title">
                <img src="../assets/logo-salman-polos.png" id="logo">
                <h1 id="login-title">YPM Salman ITB</h1>
            </div>

            <div id="little">
                <b>Log-in to your account</b>
            </div>


            <b-form @submit.prevent="userLogin">
                <div class="form-group row font-montserrat">
                    <label for="email" class="col-sm-3 col-form-label">Email</label>
                    <div class="row-sm-8">
                        <input v-model="form.email" type="text" class="form-control" id="email">
                    </div>
                </div>
                <div class="form-group row font-montserrat">
                    <label for="password" class="col-sm-3 col-form-label">Password</label>
                    <div class="row-sm-8">
                        <input v-model="form.password" v-bind:style="failed? 'border: 1px solid red;' : 'border: none;'" type="password" class="input-login form-control" id="password">
                    </div>
                    <div id="passwordView" v-on:click="changePasswordView">
                        <b-icon v-if="passwordHidden" icon="eye-fill"></b-icon>
                        <b-icon v-if="!passwordHidden" icon="eye-slash-fill"></b-icon>
                    </div>
                </div>
                
                <div class="d-flex flex-wrap justify-content-between align-items-center">
                    <a v-b-modal.modal-center class="mt-2" id="forgot-password">Forgot Password?</a>

                    <b-modal ref="modal-center" id="modal-center" centered title="Forgot Password" hide-footer>
                        <b-form v-on:submit.prevent="sendReset">
                            <label for="staticEmail">Masukkan e-mail Anda</label>
                            <div class="col-sm-10">
                            <b-form-input
                            id="input-email"
                            v-model="reset.email"
                            type="email"
                            required
                            ></b-form-input>
                            </div>
                            <div>
                            <b-button class="btn" id="btn_reset" type="submit" variant="success" @click="show=false">
                                Kirim
                            </b-button>
                        </div>
                        </b-form>
                    </b-modal>

                </div>
                <button type="submit" class="btn submit-button font-montserrat"><b>Login</b></button>
                <br>
                <p v-if="failed" id="login-failed-text" class="font-montserrat">Gagal login, cek kembali password dan email Anda</p>
            </b-form>
        </div>

        <b-modal ref="success-modal" class="font-montserrat" hide-footer hide-header no-close-on-backdrop no-close-on-esc hide-header-close>
            <div>
                <h3 class="font-montserrat">Email berhasil dikirim</h3>
            </div>
            <b-button class="mt-3 font-montserrat" variant="outline-success" block @click="closeModal">Oke</b-button>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios'
import {mapGetters} from 'vuex'

export default {
    name: "Login",
    data(){
        return{
            form: {
                email: '',
                password: '',
            },
            reset: {
                email: '',
            },
            errors: null,
            failed: false,
            passwordHidden: true,
        }
    },
    methods: {
        userLogin(){
            this.$store.dispatch('login', this.form)
            .then(response => {
                this.$router.push('/dashboard');
            }).catch(error => {
                this.failed = true;
                this.errors = error;
            })
        },
        changePasswordView(){
            this.passwordHidden = !this.passwordHidden;
            const password = document.querySelector('#password');
            const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
            password.setAttribute('type', type);
        },
        sendReset(){
            axios.post('forgot-password', this.reset)
            .then((response)=>{
            if(response.status === 200) {

                }
            })
            .catch((error)=>{
            console.log(error);
            });
            this.showModal();
            this.$refs["modal-center"].hide();
        },
        showModal(){
            this.$refs["success-modal"].show();
        },
        hideModal(){
            this.$refs["success-modal"].hide();
        },
        closeModal(){
            this.hideModal();
        },
    },
    computed: {
        ...mapGetters({
        isLoggedIn: 'isLoggedIn',
        user: 'user',
        })
    }
}
</script>

<style scoped>
    #left-side-title{
        width: 80%;
        margin: auto;
    }
    
    h1 {
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: left;
        
    }

    #left-side{
        background: #E5CA9E;
        height: 100%;
        width: 60%;
        position: fixed;
    }

    #left-side-photos{
        height: 80%;
        width: 80%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    #logo-title{
        display: grid;
        grid-template-columns: 30% 70%;
   
    }

    #login-title{
        text-align: left;
        margin-top: 0;
    }

    #logo{
        height: 89px;
        margin-top: 0;
    }

    #little{
        margin-bottom: 20px;
    }

    #login-form{
        margin-left: 65%;
        margin-right: 5%;
        padding-top: 5%;
        padding-left: 10px;
    }

    input[type="text"], input[type="password"]{
        background-color: #E5CA9E;
    }

    #input-email{
        background-color: #fff;
    }

    #forgot-password{
        font-family: "Montserrat";
        text-decoration: none;
    }

    .btn-secondary{
        border-color: none;
    }

    .submit-button{
        margin-top: 20%;
        width: 100%;
        background: #E5CA9E;
        font-size: 24px;
        color: white;
    }

    .submit-button:hover{
        background: #E5CA9E;
    }

    #login-failed-text{
        margin-top: 10px;
        color: red;
        text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    }

    .font-montserrat{
        font-family: "Montserrat";
    }

    #passwordView{
        position: relative;
        margin-left: -50px; 
        width: 10px;
        top: 5px;
        cursor: pointer;
    }

    #btn_reset {
        margin-top: 10px;
    }
    
</style>

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import VueRouter from 'vue-router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faHome, faTint, faUsers, 
         faPhone, faChild, faMapPin, faCubes, faCommenting, faKey, faSignOut } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Vuelidate from 'vuelidate'

Vue.config.silent = true

Vue.use(Vuelidate)
Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueRouter)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { setHeaderToken } from '../utils/auth'
import axios from 'axios'

library.add(faUserSecret)
library.add(faHome)
library.add(faTint)
library.add(faUsers)
library.add(faPhone)
library.add(faChild)
library.add(faMapPin)
library.add(faCubes)
library.add(faCommenting)
library.add(faKey)
library.add(faSignOut)
Vue.component('font-awesome-icon', FontAwesomeIcon)

axios.defaults.baseURL = process.env.VUE_APP_ROOT_API + 'api/'

const token = localStorage.getItem('token')

if(token){
  setHeaderToken(token)
}

// store.dispatch('get_user')
// .then(() => {
  
// }).catch((error) => {
//   console.log(error);
// })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')



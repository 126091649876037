<template>
<body>
  <b-container>
    <b-row>
        <h3>Daftar Site</h3>
    </b-row>
    <b-row>
        <p>Daftar site yang tercatat pada sistem informasi manajemen aset</p>
    </b-row>
    <div class="white-panel">
    <b-row>
        <h4>Filter</h4>
    </b-row>
    <b-form>
      <div class="row">
        <!--Kolom Kiri-->
        <div class="col">
          
          <!--Nama-->
          <div class="form-group row">
            <label for="input-search" class="col-sm-5 col-form-label">Search</label>
            <div class="col-sm-7">
              <b-form-input
                id="input-search"
                v-model="form.keyword"
                size="sm"
              ></b-form-input>
            </div>
          </div>

          <!--Jumlah tampil-->
          <div class="form-group row">
            <label for="input-6" class="col-sm-6 col-form-label">Jumlah yang Ditampilkan</label>
            <div class="col-sm-6">
              <b-form-input
                  id="input-6"
                  v-model="form.amount"
                  type="number"
                  placeholder="Pilih Jumlah"
                ></b-form-input>
            </div>
          </div>

        </div>

        <!--Kolom Kanan-->
        <div class="col">

          <!--Sort On-->
          <div class="form-group row">
            <label for="sortOn-input" class="col-sm-5 col-form-label">Urutkan Berdasarkan</label>
            <div class="col-sm-7">
              <b-form-select
                  id="sortOn-input"
                  v-model="form.sortOn"
                  placeholder="A ke B"
                  style="width: 100%; height: 100%"
                  :options="sort_on"
                ></b-form-select>
            </div>
          </div>

        </div>

        <!--Kolom Kanan-->
        <div class="col">

          <!--Sort From-->
          <div class="form-group row">
            <label for="sortFrom-input" class="col-sm-4 col-form-label">Urutkan Dari</label>
            <div class="col-sm-8">
              <b-form-select
                  id="sortFrom-input"
                  v-model="form.sortFrom"
                  placeholder="A ke B"
                  style="width: 100%; height: 100%"
                  :options="sort_from"
                ></b-form-select>
            </div>
          </div>
          
        </div>
      </div>
      
    </b-form>

    <div class="button-margin">
      <div class="d-grid d-md-flex justify-content-end">
        <b-button pill :to="{name:'ListSite', params:{ search:form.keyword, sortOn:form.sortOn, sortFrom:form.sortFrom, amount:form.amount }}" variant="success">
          <b-icon icon="search"></b-icon>
          Filter
        </b-button>
      </div>
    </div>
    
  </div>
  </b-container>
</body>
</template>

<script>
  import axios from "axios"

  export default {
    data() {
      return {
        form: {
          keyword: '',
          sortOn: null,
          sortFrom: null,
          amount: 0
        },
        sort_on: [
          { value: 'name', text: 'Nama' },
          { value: 'address', text: 'Alamat' },
          { value: 'city', text: 'Kota' },
          { value: 'state', text: 'Provinsi' },
          { value: 'postal_code', text: 'Kode Pos' }
        ],
        sort_from: [
          { value: 'desc', text: 'Besar ke Kecil' },
          { value: 'asc', text: 'Kecil ke Besar' }
        ]
      }
    }
  }
</script>

<style scoped>
p {
  font-size: 14xp;
  margin-bottom: 10px;
  font: "Montserrat";
}
h3 {
  font: "Montserrat";
  font-weight: bold;
}
 body {
     display: flex !important ;
     flex-direction: column !important ;
     background: #F1F1F1 !important ;
 }
.white-panel {
  margin-top: 20px;
  background: white !important ;
  padding: 20px 40px 20px 40px !important;
  border-radius: 10px !important;
}
.button-margin {
   padding-top: 20px !important;
}
.container {
  font-size: 14px !important
}
.form-group {
  padding-bottom: 15px !important
}
</style>
<template>
    <div id="charts">
        <div id="line-chart-container">
            <line-chart :lineLabel="lineLabels" :lineChartData="lineData"/>    
        </div>
        <div id="pie-chart-container">
            <pie-chart :pieLabel="pieLabels" :pieChartData="pieData"/>
        </div>    
    </div>
</template>

<script>
import LineChart from './LineChart.vue'
import PieChart from './PieChart.vue'

export default {
    name: 'AssetChart',
    components: {
        LineChart,
        PieChart
    },
    props: ['lineLabels', 'lineData', 'pieLabels', 'pieData']   
}
</script>

<style scoped>
    #charts{
        display: grid;
        grid-column-start: 1;
        grid-column-end: 4;
        grid-template-columns: repeat(3, 33%);;
    }

    #line-chart-container {
        grid-column-start: 1;
        grid-column-end: 3;
        margin-block: 20px;
        margin-inline: 10px;
        width: 100%;
        height: max-content;
    }

    #pie-chart-container {
        grid-column-start: 3;
        grid-column-end: 3;
        margin-block: 20px;
        margin-inline: 10px;
        width: 100%;
        height: max-content;
    }
</style>
<template>
  <div class="cont">
    <h3>Import Aset</h3>
    <p class="desc">Import Aset dari File</p>
    <div class="form_import">
    <div class="card-body">
      <form @submit="formSubmit" enctype="multipart/form-data">
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Kategori Aset </label>
        <div class="col-sm-10">
          <b-form-select
            v-model="type"
            :options="kategori"
            style="height: 35px"
          ></b-form-select>
        </div>
        <p v-if="failed.type" class="failed-text font-montserrat">{{error_message.type}}</p>  
      </div>
      <input type="file" class="form-control" v-on:change="onFileChange" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
      <p v-if="failed.doc" class="failed-text font-montserrat">{{error_message.doc}}</p>
      <br>
      <b-button class="btn" type="submit" variant="success">Submit</b-button>
      </form>
  </div>
        <b-modal ref="success-modal" class="font-montserrat" hide-footer hide-header no-close-on-backdrop no-close-on-esc hide-header-close>
          <div>
            <h3 class="font-montserrat">Aset berhasil diimpor</h3>
          </div>
          <b-button class="mt-3 font-montserrat" variant="outline-success" block @click="closeModal">Oke</b-button>
        </b-modal>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
  export default {
        data() {
            return {
              type: '',
              file: '',
              success: '',
              kategori: [
                {text:'Tanah', value:'Land'},
                {text:'Bangunan',value:'Building'},
                {text:'Kendaraan',value:'Vehicle'},
                {text:'Furniture dan Elektronik', value:'Furniture and Electronics'}
                ],
              failed: {
                type: false,
                doc: false,
              },
              error_message: {
                type: '',
                doc: '',
              },
            };
        },
        methods: {
            onFileChange(e){
                this.file = e.target.files[0];
            },
            formSubmit(e) {
                Object.keys(this.failed).forEach(k => this.failed[k] = false);
                Object.keys(this.error_message).forEach(k => this.error_message[k] = "");
                e.preventDefault();
                let currentObj = this;
   
                const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                }
    
                let formData = new FormData();
                formData.append('type', this.type);
                formData.append('file', this.file);

   
                axios.post('v1/assets/import', formData, config)
                .then( (response) => {
                    this.showModal();
                })
                .catch((error) => {
                    if(this.type === ''){
                      this.failed.type = true;
                      this.error_message.type = "Tipe aset tidak boleh kosong";
                    } else {
                      this.failed.doc = true;
                        
                      if(error.response.data.message === "Please input your file before you submit"){
                        this.error_message.doc = "Dokumen yang diunggah tidak boleh kosong";
                      } else if(error.response.data.error.message === "Error importing the files, please make sure the file imported satisfies the accepted format") {
                        this.error_message.doc = "Format dokumen excel yang diunggah salah";
                      }
                    }
                });
            },
            showModal(){
              this.$refs["success-modal"].show();
            },
            hideModal(){
              this.$refs["success-modal"].hide();
            },
            closeModal(){
              this.hideModal();
            },
        },
    }
</script>


<style scoped>

.desc{
  margin-bottom: 50px !important;
}

h3{
  margin-top: 30px !important;
}

p{
  margin-bottom: 0px !important;
  /* font-size: 14px; */
}

.import {
  margin-bottom: 30px !important;
}

.btn{
  margin-bottom: 30px !important;
}

.form_import{
  background-color: #ffff !important;
  padding: 35px !important;
  border-radius: 10px;
}

.cont h3 {
  font-weight: bold !important;
}

.cont {
  margin: 40px !important;
}

.failed-text{
  font-size: 14px;
  margin-top: 10px;
  color: red;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

.font-montserrat{
  font-family: "Montserrat";
}

.form-group{
  margin-bottom: 20px;
}
</style>

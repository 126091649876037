<template>
    <div id="pie">
        <canvas id="pie-chart" height="340px"></canvas>
    </div>
</template>

<script>
import Chart from 'chart.js'

export default{
    name: 'PieChart',
    props: [
        'pieLabel',
        'pieChartData',
    ],
    methods:{
        renderChart(){
            const ctx = document.getElementById('pie-chart')
            new Chart(ctx, {
                type: "pie",
                data: {
                labels : this.pieLabel,
                datasets : [
                    {
                        label: 'Kategori Harga',
                        data: this.pieChartData,
                        fill: false,
                        borderColor: '#20F520',
                        backgroundColor: ["#0074D9", "#FF4136", "#2ECC40", "#FF851B", "#7FDBFF", "#B10DC9", "#FFDC00", "#001f3f", "#39CCCC", "#01FF70", "#85144b", "#F012BE", "#3D9970", "#111111", "#AAAAAA"],
                        borderWidth: 0
                    }
                    ],
                },
                options: {
                    title: {
                        display: true,
                        text: 'Kategori Nilai Aset',
                        fontSize: 24,
                        fontColor: 'black',
                        fontFamily: 'Montserrat'
                    },
                    legend: {
                        display: true
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                },
            })
        }
    },
    mounted(){
        this.renderChart()
    },
    watch: {
        'pieLabel' (){
            document.querySelector("#pie").innerHTML = '<canvas id="pie-chart" height="340px"></canvas>'
            this.renderChart()
        },
        'pieChartData' (){
            document.querySelector("#pie").innerHTML = '<canvas id="pie-chart" height="340px"></canvas>'
            this.renderChart()
        }
    }
}
</script>
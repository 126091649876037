<template>
  <div class="container">
    <b-row>
      <h3>Request Aset</h3>
    </b-row>
    <b-row>
        <p>Catat aset baru atau perubahannya ke sistem informasi manajemen aset</p>
    </b-row>
    <div v-if="form.type!='delete'">
        <b-form v-if="show" enctype="multipart/form-data">
        <!--Form Atas-->
        <div class="white-panel">
        <div class="row">
        <div class="col-lg-2">
          <div class="form-group row">
            <div class="photo-container" v-if="form.type=='create'">
                  <b-img v-if="this.form.body.photo_url != null" class="photo" :src="this.endpoint + this.form.body.photo_url" fluid></b-img>
              </div>
              <div class="photo-container" v-if="form.type=='update'">
                  <b-img v-if="this.form.body.photo_url" class="photo" :src="this.endpoint + this.form.body.photo_url" fluid></b-img>
                  <b-img v-else-if="this.form.asset.photo_url" class="photo" :src="this.endpoint + this.form.asset.photo_url" fluid></b-img>
              </div>
          </div>
        </div>
        <!--Kolom Kiri-->
        <div class="col-lg-5">
          <!--Nama-->
          <div class="form-group row">
            <label for="input-name" class="col-sm-4 col-form-label">Nama</label>
            <div class="col-sm-8">
              <b-form-input
                id="input-name"
                v-model="form.body.asset_name"
                required
                readonly
                size="sm"
              ></b-form-input>
            </div>
          </div>
        
          <!--Kategori-->
          <div class="form-group row">
            <label for="input-type" class="col-sm-4 col-form-label">Kategori</label>
            <div class="col-sm-8">
              <b-form-select
                id="input-type"
                v-model="form.body.type"
                required
                disabled
                size="sm"
                style="width: 100%; height: 100%"
              >
                <b-form-select-option value="Land">Tanah</b-form-select-option>
                <b-form-select-option value="Building">Bangunan</b-form-select-option>
                <b-form-select-option value="Vehicle">Kendaraan</b-form-select-option>
                <b-form-select-option value="Furniture and Electronics">Furniture dan Elektronik</b-form-select-option>
              </b-form-select>
            </div>
          </div>

          <!--Tanggal Perolehan-->
          <div class="form-group row">
            <label for="datepicker" class="col-sm-4 col-form-label">Tanggal Perolehan</label>
            <div class="col-sm-8">
              <b-form-datepicker
                id="datepicker"
                v-model="form.body.purchase_date"
                size="sm"
                readonly
              ></b-form-datepicker>
            </div>
          </div>

          <!--Site-->
          <div class="form-group row">
            <label for="input-site" class="col-sm-4 col-form-label">Site</label>
            <div class="col-sm-8">
              <b-form-select
                id="input-site"
                v-model="form.body.site_id"
                required
                disabled
                size="sm"
                style="width: 100%; height: 100%"
              ><b-form-select-option v-for="item in this.sites" :value="item.id">{{item.name}}</b-form-select-option>
              </b-form-select>
            </div>
          </div>

        </div>

        <!--Kolom Kanan-->
        <div class="col-lg-5">

          <!--Harga Individu-->
          <div class="form-group row">
            <label for="input-costIndv" class="col-sm-3 col-form-label">Harga Per Unit</label>
            <div class="col-sm-9">
              <b-form-input
                id="input-costIndv"
                v-model="form.body.cost_individual"
                required
                readonly
                type="number"
                size="sm"
              ></b-form-input>
            </div>
          </div>

          <!--Unit-->
          <div class="form-group row">
            <label for="unit" class="col-sm-3 col-form-label">Jumlah Unit</label>
            <div class="col-sm-9">
              <b-form-input
                id="unit"
                v-model="form.body.unit"
                required
                readonly
                type="number"
                size="sm"
              ></b-form-input>
            </div>
          </div>

          <!--Cost Total-->
          <div class="form-group row">
            <label for="totalCost" class="col-sm-3 col-form-label">Total Harga</label>
            <div class="col-sm-9">
              <b-form-input
                id="totalCost"
                v-model="form.body.cost_total"
                required
                readonly
                type="number"
                size="sm"
              ></b-form-input>
            </div>
          </div>

          <!--Apakah Depreciable-->
          <div v-if="form.body.is_depreciable==1" class="form-group row">
            <label for="depreciable" class="col-sm-3 col-form-label">Depreciable</label>
            <div class="col-sm-9">
              <input type="checkbox"
                id="depreciable" 
                v-model="form.body.is_depreciable"
                value=1
                disabled
                unchecked-value=0
                style="width: 5%; height: 100%"
                >
            </div>
          </div>

        </div>
      </div>
      </div>

        <add-asset-container :form="form.body" :detail="form.body.assetable" :categories="categories" :is_depreciable="form.is_depreciable"/>

        <!--Buttons-->
        <div v-if="form.status=='pending'" class="d-flex justify-content-end">
            <b-button pill class="btn" size="sm" @click="accept()" variant="success">
            <b-icon icon="check2"></b-icon>
            </b-button>
            <b-button pill class="btn" @click="reject()" size="sm" variant="danger">
            X
            </b-button>
        </div>
        <div v-else class="d-flex justify-content-end">
            <b-button pill class="btn" size="sm" @click="accept()" variant="success" disabled>
            <b-icon icon="check2"></b-icon>
            </b-button>
            <b-button pill class="btn" @click="reject()" size="sm" variant="danger" disabled>
            X
            </b-button>
        </div>
        </b-form>
    </div>
    <div v-else>
        
    </div>
  </div>
</template>

<script>
  import AddAssetContainer from '../components/RequestAddAsset.vue'
  import axios from "axios"

  export default {
    data() {
      return {
        form: {},
        currTabId: 'detail',
        sites: null,
        categories: null,
        show: true,
        endpoint: process.env.VUE_APP_ROOT_API
      }
    },
    components:{
      AddAssetContainer
    },
    methods: {
      GetSites() {
        this.sites = []
        axios
        .get('v1/sites')
        .then(response => {this.sites = response.data.data})
        .catch (error => {console.log(error)})
      },
      GetCategory() {
        this.categories = []
        axios
        .get('v1/categories')
        .then(response => {this.categories = response.data.data})
        .catch (error => {console.log(error)})
      },
      setRequest(data){
          this.form = data
      },
      getRequest(){
          axios.get('v1/confirmations/'+this.$route.params.id)
          .then(response => {
              if(response.data.data.type != 'delete'){
                this.form=response.data.data;
                this.form.body = JSON.parse(this.form.body);
              }
          })
      },
      accept(){
            axios.get('v1/confirmations/'+this.$route.params.id+'/accept')
            .then((response)=>{
                this.$router.push({ path : '/request-list' });
            })
            .catch((error)=>{
                console.log(error);
            })
        },
        reject(){
            axios.get('v1/confirmations/'+this.$route.params.id+'/reject')
            .then((response)=>{
                this.$router.push({ path : '/request-list' });
            })
            .catch((error)=>{
                console.log(error.message);
            })
        }
    },
    mounted() {
      this.GetSites()
      this.GetCategory()
      this.getRequest()
    }
  }
</script>

<style scoped>
p {
  font-size: 14xp;
  margin-bottom: 10px;
  font: "Montserrat";
}
.d-flex{
  margin-top: 20px;
}
h3 {
  font: "Montserrat";
  font-weight: bold;
}
.btn{
  margin-left: 10px;
  width: 70px !important;
  margin-bottom: 25px !important;
}
.form-group{
  margin-top: 10px;
}
#detail {
  text-align: center;
  padding: 5px;
}
body {
  display: flex !important ;
  flex-direction: column !important ;
  background: #F1F1F1 !important ;
}
.white-panel {
  margin-top: 20px;
  background: white !important ;
  padding: 20px 40px 20px 40px !important;
  border-radius: 10px !important;
}
.container {
  font-size: 13px !important;
}
#picture {
  width: 196px;
  height: 196px;
  background-color: black;
}

.photo-container{
    width: 196px;
    height: 196px;
    background: black;
    margin-right: 20px !important;
    padding: 10px;
}
.photo{
    width: 174px;
    height: 172px;
}
</style>

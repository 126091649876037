<template>
</template>

<script>
import axios from 'axios';
  export default {
        mounted() {
            this.$store.dispatch("logout").then(
                () => {
                this.$router.push("/login")
                }
            )
        }
    }
</script>
<template>
    <div id="information-container">
        <div id="information-navbar">
            <ul class="list-group list-group-horizontal">
                <li><div id="tanah" class="selected"><a  @click="switchTab('tanah')">Tanah</a></div></li>
                <li><div id="bangunan"><a @click="switchTab('bangunan')">Bangunan</a></div></li>
                <li><div id="kendaraan"><a @click="switchTab('kendaraan')">Kendaraan</a></div></li>
                <li><div id="furniture"><a @click="switchTab('furniture')">Furniture & Elektronik</a></div></li>
            </ul>
        </div>

        <div id="block-container">
            <asset-chart
            :lineLabels="lineLabels"
            :lineData="lineData"
            :pieData="pieData"
            :pieLabels="pieLabels"/>
            
            <information-block v-if="currTabId === 'tanah'" :title="'Jumlah Aset Tanah'" :info="info2" ico_src="land.svg"></information-block>
            <information-block v-else-if="currTabId === 'bangunan'" :title="'Jumlah Bangunan'" :info="info2" ico_src="bank2.svg"></information-block>
            <information-block v-else-if="currTabId === 'kendaraan'" :title="'Jumlah Kendaraan'" :info="info2" ico_src="truck.svg"></information-block>
            <information-block v-else :title="'Jumlah Total'" :info="info2" ico_src="chair.svg"></information-block>

            <information-block :title="'Nilai Aset'" :info="info3" ico_src="currency-exchange.svg"/>

            <information-block v-if="currTabId === 'tanah'" :title="'Luas Tanah Total'" :info="info41" ico_src="land-parcels.svg"></information-block>
            <information-block v-else-if="currTabId === 'bangunan'" :title="'Luas Bangunan Total'" :info="info42" ico_src="building.svg"></information-block>
            <information-block v-else-if="currTabId === 'kendaraan'" :title="'Banyak Kendaraan Roda 2'" :info="info43a" ico_src="bicycle.svg"></information-block>
            <information-block v-else :title="'Jumlah Komputer'" :info="info44" ico_src="pc-display.svg"></information-block>

            <information-block v-if="currTabId === 'kendaraan'" :title="'Banyak Kendaraan Roda 4'" :info="info43b" ico_src="truck.svg"></information-block>
            <information-block v-if="currTabId === 'furniture'" :title="'Jumlah Multimedia'" :info="info5" ico_src="headset.svg"></information-block>
            <information-block v-if="currTabId === 'furniture'" :title="'Jumlah Furniture'" :info="info6" ico_src="cup.svg"></information-block>
            <information-block v-if="currTabId === 'furniture'" :title="'Jumlah Lainnya'" :info="info7" ico_src="bricks.svg"></information-block>
        </div>
    </div>    
</template>

<script>
import axios from "axios"
import InformationBlock from "./InformationBlock.vue"
import AssetChart from "./Chart.vue"

export default {
    name: "InformationContainer",
    components: {
        InformationBlock,
        AssetChart
    },
    data(){
        return{
            info1: 'Lorem ipsum',
            title2: 'Jumlah Aset Aktif',
            info2: '0',
            info3: '0',
            info41: '0',
            info42: '0',
            info43a: '0',
            info43b: '0',
            info44: '0',
            info5: '0',
            info6: '0',
            info7: '0',
            lineLabels: [],
            lineData: [],
            pieLabels: [],
            pieData:  [],
            currTabId: 'tanah',
        }
    },
    methods: {
        GetData: function(tab){
            const tabToAPI = {
                'tanah': 'lands',
                'bangunan': 'buildings',
                'kendaraan': 'vehicles',
                'furniture': 'furnitures',
            }
            axios.get('v1/home/' + tabToAPI[tab])
            .then(response => {
                this.info2 = this.giveDots(response.data.data.count)
                this.info3 = 'Rp. ' + this.giveDots(response.data.data.total_value)
                // this.info3 = 'Rp. ' + this.giveDots(10000000000000000000)
                var two = '2'
                if(tabToAPI[tab] === 'lands'){
                    this.info41 = this.giveDots(response.data.data.total_area) + " m²"
                } else if(tabToAPI[tab] === 'buildings'){
                    this.info42 = this.giveDots(response.data.data.total_area) + " m²"
                } else if(tabToAPI[tab] === 'vehicles'){
                    this.info43a = this.giveDots(response.data.data.total_2wheel)
                    this.info43b = this.giveDots(response.data.data.total_4wheel)
                } else {
                    this.info44 = this.giveDots(response.data.data.total_computer)
                    this.info5 = this.giveDots(response.data.data.total_electronic)
                    this.info6 = this.giveDots(response.data.data.total_furniture)
                    this.info7 = this.giveDots(response.data.data.total_others)
                }
                var rawLineLabels = Object.keys(response.data.data.asset_value_each_year)
                var rawLineData = Object.values(response.data.data.asset_value_each_year)
                var rawPieLabels = Object.keys(response.data.data.asset_range)
                var rawPieData = Object.values(response.data.data.asset_range)

                // Hapus data yang tahunnya lebih dari 30 tahun sejak tahun ini
                var currYear = 2025 // Default year 
                currYear = new Date().getFullYear()
                for(var i=0; i < rawLineLabels.length; i++){
                    if(parseInt(rawLineLabels[i]) >= (currYear + 30)){
                        rawLineData.splice(i, rawLineData.length - i)
                        rawLineLabels.splice(i, rawLineLabels.length - i)
                        break
                    }
                }

                // Hapus data yang tahunnya lebih dari 10 tahun sebelum tahun ini
                for(var i=0; i < rawLineLabels.length; i++){
                    if(parseInt(rawLineLabels[i]) >= (currYear - 10)){
                        if(i == 0){
                            break
                        } else {
                            rawLineData.splice(0, i)
                            rawLineLabels.splice(0, i)
                            break
                        }
                    }
                }

                // Hapus kategori harga yang banyaknya 0
                for(var i=rawPieData.length - 1; i >= 0; i--){
                    if(rawPieData[i] === 0){
                        rawPieLabels.splice(i, 1)
                    }
                }
                rawPieData = rawPieData.filter(datum => datum !== 0)

                this.lineLabels = rawLineLabels
                this.lineData = rawLineData
                this.pieLabels = rawPieLabels
                this.pieData = rawPieData
            })
            .catch(err => {
                console.log(err)
            })
        },
        switchTab: function (targetTabId){
            if(this.currTabId != targetTabId){
                document.getElementById(this.currTabId).classList.remove("selected")
                document.getElementById(targetTabId).classList.add("selected")
                this.currTabId = targetTabId
                this.GetData(targetTabId)
            }
        },
        giveDots: function(num){
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        }
    },
    created(){
        this.GetData(this.currTabId)
    }
}
</script>

<style scoped>
    #information-navbar{
        background: #121212;
        color: white;
        border-radius: 10px;
    }

    #block-container {
        background: #ffffff;
        display: grid;
        grid-template-columns: repeat(3, 33%);
        grid-template-rows: repeat(3, auto);
        justify-items: stretch;
        justify-content: center;    
        text-align: left;
    }
    
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        align-items: center;
    }
    li {
        padding-inline: 9%;
    }

    li a {
        display: block;
        color: white;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
    }
    .selected{
        border-bottom: 4px solid #F9D68C;
    }
</style>


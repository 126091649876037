<template>
<body>
  <b-container>
    <b-row>
        <h3>Akun Pengguna</h3>
    </b-row>
    <b-row>
        <p>Daftar akun pengguna sistem informasi manajemen aset</p>
    </b-row>
    <div class="white-panel">
    <b-row>
        <h4>Filter</h4>
    </b-row>
    <b-form>
      <div class="row">
        <!--Kolom Kiri-->
        <div class="col">
          
          <!--Nama-->
          <div class="form-group row">
            <label for="input-search" class="col-sm-4 col-form-label">Search</label>
            <div class="col-sm-8">
              <b-form-input
                id="input-search"
                v-model="form.keyword"
                required
                size="sm"
              ></b-form-input>
            </div>
          </div>
        
          <!--Sort On-->
          <div class="form-group row">
            <label for="sortOn-input" class="col-sm-5 col-form-label">Urutkan Berdasarkan</label>
            <div class="col-sm-7">
              <b-form-select
                  id="sortOn-input"
                  v-model="form.sortOn"
                  placeholder="A ke B"
                  style="width: 100%; height: 100%"
                  :options="sort_on"
                ></b-form-select>
            </div>
          </div>

          <!--Jumlah tampil-->
          <div class="form-group row">
            <label for="input-6" class="col-sm-6 col-form-label">Jumlah yang Ditampilkan</label>
            <div class="col-sm-6">
              <b-form-input
                  id="input-6"
                  v-model="form.amount"
                  type="number"
                  placeholder="Pilih Jumlah"
                ></b-form-input>
            </div>
          </div>

        </div>

        <!--Kolom Kanan-->
        <div class="col">

          <!--Kategori-->
          <div class="form-group row">
            <label for="type-input" class="col-sm-3 col-form-label">Tipe Akun</label>
            <div class="col-sm-9">
              <b-form-select
                  id="type-input"
                  v-model="form.type"
                  placeholder="Pilih Tipe Akun"
                  style="width: 100%; height: 100%"
                  :options="types"
                ></b-form-select>
            </div>
          </div>
          

          <!--Sort From-->
          <div class="form-group row">
            <label for="sortFrom-input" class="col-sm-4 col-form-label">Urutkan Dari</label>
            <div class="col-sm-8">
              <b-form-select
                  id="sortFrom-input"
                  v-model="form.sortFrom"
                  placeholder="A ke B"
                  style="width: 100%; height: 100%"
                  :options="sort_from"
                ></b-form-select>
            </div>
          </div>

        </div>

        <!--Kolom Kanan-->
        <div class="col">

          <!--Site-->
          <div class="form-group row">
            <label for="input-site" class="col-sm-2 col-form-label">Site</label>
            <div class="col-sm-7">
              <b-form-select
                id="input-site"
                v-model="form.site"
                required
                size="sm"
                style="width: 100%; height: 100%"
              ><b-form-select-option v-for="item in this.sites" :value="item.id">{{item.name}}</b-form-select-option>
              </b-form-select>
            </div>
          </div>
          
        </div>
      </div>
        
    </b-form>

    <div class="button-margin">
      <div class="d-grid d-md-flex justify-content-end">
        <b-button pill :to="{name:'ListofAccount', params:{ type:form.type, search:form.keyword, site_id:form.site, sortOn:form.sortOn, sortFrom:form.sortFrom, amount:form.amount }}" variant="success">
          <b-icon icon="search"></b-icon>
          Filter
        </b-button>
      </div>
    </div>
    
  </div>
  </b-container>
</body>
</template>

<script>
  import axios from "axios"

  export default {
    data() {
      return {
        form: {
          keyword: '',
          type: null,
          site: null,
          sortOn: null,
          sortFrom: null,
          amount: 0
        },
        types: [
          { value: 0, text: 'Massa Umum Salman' },
          { value: 1, text: 'Anggota Biasa' },
          { value: 2, text: 'Anggota Utama' },
        ],
        sites: null,
        sort_on: [
          { value: 'username', text: 'Username' },
          { value: 'name', text: 'Nama Akun' },
          { value: 'email', text: 'Email' },
          { value: 'department', text: 'Departemen' }
        ],
        sort_from: [
          { value: 'desc', text: 'Besar ke Kecil' },
          { value: 'asc', text: 'Kecil ke Besar' }
        ]
      }
    },
    mounted() {
      this.sites = []
      axios
      .get('api/v1/sites')
      .then(response => {this.sites = response.data.data})
      .catch (error => {console.log(error)})
    }
  }
  
</script>

<style scoped>
p {
  font-size: 14xp;
  margin-bottom: 10px;
  font: "Montserrat";
}
h3 {
  font: "Montserrat";
  font-weight: bold;
}
 body {
     display: flex !important ;
     flex-direction: column !important ;
     background: #F1F1F1 !important ;
 }
.white-panel {
  margin-top: 20px;
  background: white !important ;
  padding: 20px 40px 20px 40px !important;
  border-radius: 10px !important;
}
.button-margin {
   padding-top: 20px !important;
}
.container {
  font-size: 14px !important
}
.form-group {
  padding-bottom: 15px !important
}
</style>

<template>
  <div class="cont">
    <h3>Penambahan Site Baru</h3>
    <div class="form_site">
    <b-form v-on:submit.prevent="save" @reset="onReset" v-if="show" novalidate>
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Nama</label>
        <div class="col-sm-10">
        <b-form-input
          id="input-2"
          v-model="form.name"
          required
        ></b-form-input>
        <p v-if="failed.name" class="failed-text font-montserrat">{{error_message.name}}</p>
        </div>
        </div>

      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Deskripsi</label>
        <div class="col-sm-10">
        <b-form-textarea
          id="input-3"
          v-model="form.description"
          required
        ></b-form-textarea>
        <p v-if="failed.description" class="failed-text font-montserrat">{{error_message.description}}</p>
        </div>
      </div>

      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Alamat</label>
        <div class="col-sm-10">
        <b-form-textarea
          id="input-3"
          v-model="form.address"
          required
        ></b-form-textarea>
        <p v-if="failed.address" class="failed-text font-montserrat">{{error_message.address}}</p>
        </div>
      </div>

      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Kota / Kabupaten</label>
        <div class="col-sm-10">
        <b-form-input
          id="input-2"
          v-model="form.city"
          required
        ></b-form-input>
        <p v-if="failed.city" class="failed-text font-montserrat">{{error_message.city}}</p>
        </div>
      </div>

      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Provinsi</label>
        <div class="col-sm-10">
        <b-form-input
          id="input-2"
          v-model="form.state"
          required
        ></b-form-input>
        <p v-if="failed.state" class="failed-text font-montserrat">{{error_message.state}}</p>
        </div>
      </div>

      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Kode Pos</label>
        <div class="col-sm-10">
        <b-form-input
          style="width:100px"
          :formatter="formatPostal"
          id="input-2"
          v-model="form.postal_code"
          required
        ></b-form-input>
        <p v-if="failed.postal_code" class="failed-text font-montserrat">{{error_message.postal_code}}</p>
        </div>
      </div>

      <div class="d-flex justify-content-end">
          <b-button pill class="btn" type="submit" variant="success">
            <b-icon icon="check2"></b-icon>
          </b-button>
          <b-button pill class="btn" type="reset" variant="warning">
            <b-icon icon="arrow-counterclockwise"></b-icon>
          </b-button>
          <b-button pill class="btn" @click="goBack()" variant="danger">
            <b-icon icon="arrow-left"></b-icon>
          </b-button>
      </div>

    </b-form>
      <b-modal ref="success-modal" class="font-montserrat" hide-footer hide-header no-close-on-backdrop no-close-on-esc hide-header-close>
        <div>
          <h3 class="font-montserrat">Site berhasil ditambahkan</h3>
        </div>
        <b-button class="mt-3 font-montserrat" variant="outline-success" block @click="closeModal">Oke</b-button>
      </b-modal>
    </div>
    
    <!-- <b-card class="mt-3" header="Form Data Result">
      <pre class="m-0">{{ form }}</pre>
    </b-card> -->
  </div>
</template>

<script>
  import axios from 'axios';
  export default {
    data() {
      return {
        form: {
          name: '',
          description: '',
          address: '',
          city: '',
          state: '',
          postal_code: ''
        },
        show: true,
        failed: {
          name: false,
          description: false,
          address: false,
          city: false,
          state: false,
          postal_code: false,
        },
        error_message: {
          name: "",
          description: "",
          address: "",
          city: "",
          state: "",
          postal_code: "",
        }
      }
    },
    methods: {
      onReset(event) {
        Object.keys(this.failed).forEach(k => this.failed[k] = false);
        Object.keys(this.error_message).forEach(k => this.error_message[k] = "");
        event.preventDefault()
        // Reset our form values
        this.form.name = ''
        this.form.description = ''
        this.form.address = ''
        this.form.city = ''
        this.form.state = ''
        this.form.postal_code = ''
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      },
      save(){
        Object.keys(this.failed).forEach(k => this.failed[k] = false);
        Object.keys(this.error_message).forEach(k => this.error_message[k] = "");
        axios.post('v1/sites', this.form)
        .then((response)=>{
          if(response.status === 200) {
              this.showModal();
            }
        }).catch((error)=>{
          const columnTranslated = {
            "name": "nama",
            "description" : "deskripsi",
            "address": "alamat",
            "city": "kota/kabupaten",
            "state": "provinsi",
            "postal_code": "kode pos",
          }
          Object.keys(this.form).forEach( (key) => {
            if(error.response.data.error.errors.hasOwnProperty(key)){
              this.failed[key] = true;
              this.error_message[key] = "Kolom " + columnTranslated[key] + " tidak boleh kosong";
            }
          })
        })
      },
      goBack(){
        this.$router.go(-1)
      },
      formatPostal(e){
        return String(e).substring(0,5);
      },
      showModal(){
        this.$refs["success-modal"].show();
      },
      hideModal(){
        this.$refs["success-modal"].hide();
      },
      closeModal(){
        this.hideModal();
        this.$router.push({ path : '/listsite' });
      },
    }
  }
</script>

<style scoped>
label{
  font-size: 14px;
}
.form-group {
  margin-bottom: 20px !important;
}

.btn{
  margin-left: 10px !important;
  width: 70px !important;
}

h3{
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.form_site{
  background-color: #ffff !important;
  padding: 35px !important;
  border-radius: 10px;
}

.cont h3 {
  font-weight: bold;
}

.cont {
  margin: 40px;
}

.failed-text{
  font-size: 14px;
  margin-top: 10px;
  color: red;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

.font-montserrat{
  font-family: "Montserrat";
}
</style>

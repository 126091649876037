<template>
    <div id="information-container">
        <div class="my-auto">
            <h2 id="information-title">{{title}}</h2>
            <h3 id="information-content">{{info}}</h3>
        </div>
        <div class="my-auto">
            <img :src="require(`../assets/icons/${ico_src}`)" />
        </div>
    </div>    
</template>

<script>
export default {
    name: "InformationBlock",
    props: ['title', 'info', 'ico_src']
}
</script>

<style scoped>
    #information-container {
        display: grid;
        grid-template-columns: 50% 50%;
        height: 250px;
        background: #E5CA9E;
        border-radius: 20px;
        margin-block: 20px;
        margin-inline: 10px;
        padding: 30px 0 30px 30px;
    }

    #information-container h2{
        font-size: 24px;
    }

    #information-container h3{
        font-size: 150%;
        word-wrap: break-word;
        font-weight: bold;
        margin-top: 25%;
    }

    #information-container img{
        width: 80%;
        height: 80%;
    }

    #information-content{
        margin-top: 10px;
    }
</style>
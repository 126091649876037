<template>
  <div class="cont">
    <h3>Penambahan Akun Baru</h3>
    <div class="form_account">
    <b-form v-on:submit.prevent="save" @reset="onReset" v-if="show" novalidate>
      <div class="row">
      <div class="col">
        <b-form-group id="input-group-3" label="Nama" label-size="sm" label-for="input-2">
          <b-form-input
            id="input-2"
            v-model="form.name"
            required
          ></b-form-input>
          <p v-if="failed.name" class="failed-text font-montserrat">{{error_message.name}}</p>
        </b-form-group>
      </div>

      <div class="col">
        <b-form-group id="input-group-3" label="Tipe" label-size="sm" label-for="input-3">
          <b-form-select
            id="input-3"
            v-model="form.type"
            :options="listtipe"
            required
            style="width: 250px; height: 35px"
          ></b-form-select>
          <p v-if="failed.type" class="failed-text font-montserrat">{{error_message.type}}</p>
        </b-form-group>
      </div>
      </div>

      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Username</label>
        <div class="col-sm-10">
          <b-form-input
          id="input-2"
          v-model="form.username"
          required
        ></b-form-input>
        <p v-if="failed.username" class="failed-text font-montserrat">{{error_message.username}}</p>
        </div>
      </div>

      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Jabatan</label>
        <div class="col-sm-10">
          <b-form-input
          id="input-2"
          v-model="form.position"
          required
        ></b-form-input>
        <p v-if="failed.position" class="failed-text font-montserrat">{{error_message.position}}</p>
        </div>
      </div>

      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Departemen</label>
        <div class="col-sm-10">
          <b-form-input
          id="input-2"
          v-model="form.department"
          required
        ></b-form-input>
        <p v-if="failed.department" class="failed-text font-montserrat">{{error_message.department}}</p>
        </div>
      </div>

      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">No. Hp</label>
        <div class="col-sm-10">
          <b-form-input
          id="input-2"
          type="tel"
          v-model="form.phone_number"
          required
        ></b-form-input>
        <p v-if="failed.phone_number" class="failed-text font-montserrat">{{error_message.phone_number}}</p>
        </div>
      </div>

      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Email</label>
        <div class="col-sm-10">
          <b-form-input
          id="input-2"
          v-model="form.email"
          required
        ></b-form-input>
        <p v-if="failed.email" class="failed-text font-montserrat">{{error_message.email}}</p>
        </div>
      </div>

      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Password</label>
        <div class="col-sm-10">
          <b-form-input
          id="input-2"
          v-model="form.password"
          required
        ></b-form-input>
        <p v-if="failed.password" class="failed-text font-montserrat">{{error_message.password}}</p>
        </div>
      </div>

      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Password Konfirmasi</label>
        <div class="col-sm-10">
          <b-form-input
          id="input-2"
          v-model="form.password_confirmation"
          required
        ></b-form-input>
        <p v-if="failed.password_confirmation" class="failed-text font-montserrat">{{error_message.password_confirmation}}</p>
        </div>
      </div>

      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Site</label>
        <div class="col-sm-4">
          <b-form-select
            id="site-input"
            v-model="form.site_id"
            placeholder="Pilih Site"
            required
            style="width: 100%; height: 35px"
          >
          <b-form-select-option v-for="item in this.sites" :value="item.id" :key="item.id">{{item.name}}</b-form-select-option>
          </b-form-select>
          <p v-if="failed.site_id" class="failed-text font-montserrat">{{error_message.site_id}}</p>
        </div>
      </div>

      <div class="d-flex justify-content-end">
          <b-button pill class="btn" type="submit" variant="success">
            <b-icon icon="check2"></b-icon>
          </b-button>
          <b-button pill class="btn" type="reset" variant="warning">
            <b-icon icon="arrow-counterclockwise"></b-icon>
          </b-button>
          <b-button pill class="btn" @click="goBack()" variant="danger">
            <b-icon icon="arrow-left"></b-icon>
          </b-button>
      </div>
    </b-form>
        <b-modal ref="success-modal" class="font-montserrat" hide-footer hide-header no-close-on-backdrop no-close-on-esc hide-header-close>
          <div>
            <h3 class="font-montserrat">Akun berhasil ditambahkan</h3>
          </div>
        <b-button class="mt-3 font-montserrat" variant="outline-success" block @click="closeModal">Oke</b-button>
      </b-modal>
    </div>
    <!-- <b-card class="mt-3" header="Form Data Result">
      <pre class="m-0">{{ form }}</pre>
    </b-card> -->
  </div>
</template>

<script>
// import {minLength, maxLength } from 'vuelidate/lib/validators'
import axios from 'axios';
  export default {
    data() {
      return {
        form: {
          username: '',
          password: '',
          password_confirmation: '',
          type: null,
          name: '',
          email: '',
          phone_number: '',
          site_id: null,
          department: '',
          position: ''
        },
        listtipe: [
          { text: 'Masa Umum Salman', value: 0},
          { text: 'Anggota Biasa', value: 1},
          { text: 'Anggota Utama', value: 2}
          ],
        sites: [],
        show: true,
        failed: {
          username: false,
          password: false,
          password_confirmation: false,
          type: false,
          name: false,
          email: false,
          phone_number: false,
          site_id: false,
          department: false,
          position: false,
        },
        error_message: {
          username: '',
          password: '',
          password_confirmation: '',
          type: '',
          name: '',
          email: '',
          phone_number: '',
          site_id: '',
          department: '',
          position: ''
        },
      }
    },
    methods: {
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        Object.keys(this.failed).forEach(k => this.failed[k] = false);
        Object.keys(this.error_message).forEach(k => this.error_message[k] = "");
        this.form.name = ''
        this.form.type = null
        this.form.username = ''
        this.form.position = ''
        this.form.department = ''
        this.form.phone_number = ''
        this.form.email = ''
        this.form.password= ''
        this.form.password_confirmation=''
        this.form.site_id= null
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      },
      save(){
        Object.keys(this.failed).forEach(k => this.failed[k] = false);
        Object.keys(this.error_message).forEach(k => this.error_message[k] = "");
        axios.post('v1/users', this.form)
        .then((response)=>{
          if(response.status === 200) {
              this.showModal();
            }
        }).catch((error)=>{
          const columnTranslated = {
            "name": "nama",
            "type" : "tipe",
            "username": "username",
            "position": "posisi",
            "department": "departemen",
            "phone_number": "nomor telepon",
            "email": "email",
            "password": "password",
            "password_confirmation": "konfirmasi password",
            "site_id": "site"
          }

          // Cek apakah ada masalah di suatu kolom
          Object.keys(this.form).forEach( (key) => {
            if(error.response.data.error.errors.hasOwnProperty(key)){
              this.failed[key] = true;
              this.error_message[key] = "Kolom " + columnTranslated[key] + " tidak boleh kosong";

              if(error.response.data.error.errors[key].includes("The " + key + " must not be greater than 255 characters.")){
                this.error_message[key] = columnTranslated[key] + " tidak boleh lebih dari 255 karakter";
              }

              if(key === "email" && error.response.data.error.errors[key].includes("The email must be a valid email address.")){
                this.error_message[key] = "Masukkan email yang valid";
              }

              if(error.response.data.error.errors[key].includes("The " + key + " has already been taken.")){
                this.error_message[key] = columnTranslated[key] + " ini sudah ada yang pakai";
              }

              if(key === "phone_number"){
                if(error.response.data.error.errors[key].includes("The phone number format is invalid.")){
                  this.error_message[key] = "Nomor telepon harus merupakan deretan angka";
                } else if(error.response.data.error.errors[key].includes("The phone number must be at least 10 characters.")){
                  this.error_message[key] = "Nomor telepon harus punya minimal 10 digit";
                } else if(error.response.data.error.errors[key].includes("The phone number must not be greater than 13 characters.")){
                  this.error_message[key] = "Nomor telepon harus punya maksimal 13 digit";
                }
              }

              if(key === "password"){
                if(error.response.data.error.errors[key].includes("The password must be at least 6 characters.")){
                  this.error_message[key] = "Password harus memiliki minimal 6 karakter";
                } else if(error.response.data.error.errors[key].includes("The password confirmation does not match.")){
                  this.failed.password_confirmation = true;
                  this.error_message.password_confirmation = "Konfirmasi password berbeda dengan masukan password";
                  this.error_message.password = "";
                }
              }
            }
          })

          if(this.form.site_id === null){
            this.failed.site_id = true;
            this.error_message.site_id = "Kolom site tidak boleh kosong";
          }
        })
      },
      getSites(){
        axios.get('v1/sites')
        .then(response => {
            this.sites = response.data['data'];
        });
      },
      goBack(){
        this.$router.go(-1)
      },
      showModal(){
        this.$refs["success-modal"].show();
      },
      hideModal(){
        this.$refs["success-modal"].hide();
      },
      closeModal(){
        this.hideModal();
        this.$router.push({ path : '/listaccount' });
      },
    },
    created(){
      this.getSites();
    }
  }
</script>


<style scoped>
h3{
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.form-group{
  margin-top: 10px !important;
}

.d-flex{
  margin-top: 20px !important;
}

.btn{
  margin-left: 10px !important;
  width: 70px !important;
}

.form_account{
  background-color: #ffff !important;
  padding: 35px !important;
  border-radius: 10px !important;
}

.cont h3 {
  font-weight: bold !important;
}

.cont {
  margin-left: 40px;
  margin-right: 40px;
  min-height: 100vh !important;
}

label {
  font-size: 14px;
}

#input-group-3 label {
  font-size: 14px;
}
.failed-text{
  font-size: 14px;
  margin-top: 10px;
  color: red;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

p::first-letter{
  text-transform: capitalize;
}

.font-montserrat{
  font-family: "Montserrat";
}

</style>
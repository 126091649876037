<template>
  <div class="container">
    <b-row v-if="request.type=='delete' && request.model=='document'">
      <h3>Request Delete Document</h3>
    </b-row>
    <b-row v-if="request.type=='create' && request.model=='document'">
      <h3>Request Add Document</h3>
    </b-row>
    <b-row v-if="request.type=='delete' && request.model=='gallery'">
      <h3>Request Delete Gallery</h3>
    </b-row>
    <b-row v-if="request.type=='create' && request.model=='gallery'">
      <h3>Request Add Gallery</h3>
    </b-row>
    <b-form>
    
    <div class="white-panel">
      <!--Head-->
      <b-row>
        <b-col>
          <h4 v-if="this.form.asset_name">{{this.form.asset_name}}</h4>
        
        </b-col>
        <b-col>
          
        </b-col>
      </b-row>

      <b-row>
        <b-col>
            <b-row>
              <div class="photo-container">
                  <b-img v-if="this.form.photo_url" class="photo" :src="this.endpoint + this.form.photo_url" fluid></b-img>
              </div>

              <b-col>
              <div class="row">
              <!--Kolom Kiri-->
              <div class="col">

                <!--ID-->
                <div class="form-group row">
                  <label for="input-2" class="col-sm-4 col-form-label">Asset ID</label>
                  <div class="col-sm-8">
                    <b-form-input v-if="this.form.asset_name"
                          id="input-2"
                          v-model="this.form.id"
                          disabled
                          required
                          size="sm"
                      ></b-form-input>
                  </div>
                </div>
                
                <!--Nama-->
                <div class="form-group row">
                  <label for="input-name" class="col-sm-4 col-form-label">Nama</label>
                  <div class="col-sm-8">
                    <b-form-input
                      id="input-name"
                      v-model="form.asset_name"
                      required
                      disabled
                      size="sm"
                    ></b-form-input>
                  </div>
                </div>
              
                <!--Kategori-->
                <div class="form-group row">
                  <label for="input-type" class="col-sm-4 col-form-label">Kategori</label>
                  <div class="col-sm-8">
                    <b-form-select
                      id="input-type"
                      v-model="form.type"
                      required
                      disabled
                      size="sm"
                      style="width: 100%; height: 100%"
                    >
                      <b-form-select-option value="Land">Tanah</b-form-select-option>
                      <b-form-select-option value="Building">Bangunan</b-form-select-option>
                      <b-form-select-option value="Vehicle">Kendaraan</b-form-select-option>
                      <b-form-select-option value="Furniture and Electronics">Furniture dan Elektronik</b-form-select-option>
                    </b-form-select>
                  </div>
                </div>

                <!--Tanggal Perolehan-->
                <div class="form-group row">
                  <label for="datepicker" class="col-sm-4 col-form-label">Tanggal Perolehan</label>
                  <div class="col-sm-8">
                    <b-form-datepicker
                      id="datepicker"
                      v-model="form.purchase_date"
                      disabled
                      size="sm"
                    ></b-form-datepicker>
                  </div>
                </div>

              </div>

              <!--Kolom Kanan-->
              <div class="col">

                <!--Site-->
                <div class="form-group row">
                  <label for="input-site" class="col-sm-4 col-form-label">Site</label>
                  <div class="col-sm-8">
                    <b-form-select
                      id="input-site"
                      v-model="form.site_id"
                      required
                      disabled
                      size="sm"
                      style="width: 100%; height: 100%"
                    ><b-form-select-option v-for="item in this.sites" :value="item.id">{{item.name}}</b-form-select-option>
                    </b-form-select>
                  </div>
                </div>

                <!--Harga Individu-->
                <div class="form-group row">
                  <label for="input-costIndv" class="col-sm-4 col-form-label">Harga Per Unit</label>
                  <div class="col-sm-8">
                    <b-form-input
                      id="input-costIndv"
                      v-model="form.cost_individual"
                      required
                      type="number"
                      disabled
                      size="sm"
                    ></b-form-input>
                  </div>
                </div>

                <!--Unit-->
                <div class="form-group row">
                  <label for="unit" class="col-sm-4 col-form-label">Jumlah Unit</label>
                  <div class="col-sm-8">
                    <b-form-input
                      id="unit"
                      v-model="form.unit"
                      required
                      type="number"
                      disabled
                      size="sm"
                    ></b-form-input>
                  </div>
                </div>

                <!--Cost Total-->
                <div class="form-group row">
                  <label for="totalCost" class="col-sm-4 col-form-label">Total Harga</label>
                  <div class="col-sm-8">
                    <b-form-input
                      id="totalCost"
                      v-model="form.cost_total"
                      required
                      type="number"
                      disabled
                      size="sm"
                    ></b-form-input>
                  </div>
                </div>

              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
  
  <detail-container :currTabId="currTabId" :form="form" :documents="documents" :gallery="gallery" :categories="categories" :isEdit="false" :request="request"/>
  <div v-if="request.status=='pending'" class="d-flex justify-content-end">
            <b-button pill class="btn" size="sm" @click="accept()" variant="success">
            <b-icon icon="check2"></b-icon>
            </b-button>
            <b-button pill class="btn" @click="reject()" size="sm" variant="danger">
            X
            </b-button>
        </div>
        <div v-else class="d-flex justify-content-end">
            <b-button pill class="btn" size="sm" @click="accept()" variant="success" disabled>
            <b-icon icon="check2"></b-icon>
            </b-button>
            <b-button pill class="btn" @click="reject()" size="sm" variant="danger" disabled>
            X
            </b-button>
        </div>
</b-form>
  </div>
</template>

<script>
  import DetailContainer from '../components/DetailContainerDocument.vue'
  import axios from "axios"
  import {mapGetters} from 'vuex'

  export default {
    props: ['id'],
    data() {
      return {
        form: {},
        request:{},
        currTabId: 'detail',
        show: true,
        sites: null,
        categories: null,
        documents: null,
        gallery: null,
        types: null,
        photo: null,
        endpoint: process.env.VUE_APP_ROOT_API
      }
    },
    components:{
      DetailContainer
    },
    methods: {
        getRequest(){
            axios.get('v1/confirmations/'+this.$route.params.id)
            .then(response => {
                this.request=response.data.data;
                this.GetData(this.request.asset_id)
                if (this.request.model=='document') {
                  if (this.request.type=='create') {
                  this.request.body = JSON.parse(this.request.body);
                  this.documents=[]
                  this.documents[0] = response.data.data.body;
                  } else {
                    this.GetDocuments(this.request.asset_id, this.request.document_id)
                  }
                }
                else if (this.request.model=='gallery') {
                  if (this.request.type=='create') {
                  this.request.body = JSON.parse(this.request.body);
                  this.gallery=[]
                  this.gallery[0] = response.data.data.body;
                  } else {
                    this.GetGallery(this.request.asset_id, this.request.galleries_id)
                  }
                }
            })
        },
        GetData(id) {
            var link = 'v1/assets/' + id + '?eagerLoad=true'
            axios
            .get(link)
            .then(
              response => {this.form = response.data.data})
            .catch (error => {console.log(error)})
        },
        GetDocuments(id, doc_id) {
          this.documents = []
          var link = 'v1/assets/' + id + '/documents/'+ doc_id
          axios
          .get(link)
          .then(response => {this.documents[0] = response.data.data;})
          .catch (error => {console.log(error.message)})
        },
        GetGallery(id, gal_id) {
          this.gallery = []
          var link = 'v1/assets/' + id + '/galleries/' + gal_id
          axios
          .get(link)
          .then(response => {this.gallery[0] = response.data.data})
          .catch (error => {console.log(error)})
        },
        GetSites() {
          this.sites = []
          axios
          .get('v1/sites')
          .then(response => {this.sites = response.data.data})
          .catch (error => {console.log(error)})
        },
        GetCategories() {
          this.categories = []
          axios
          .get('v1/categories')
          .then(response => {this.categories = response.data.data})
          .catch (error => {console.log(error)})
        },
        accept(){
            axios.get('v1/confirmations/'+this.$route.params.id+'/accept')
            .then((response)=>{
                this.$router.push({ path : '/request-list' });
            })
            .catch((error)=>{
                console.log(error.message);
            })
        },
        reject(){
            axios.get('v1/confirmations/'+this.$route.params.id+'/reject')
            .then((response)=>{
                this.$router.push({ path : '/request-list' });
            })
            .catch((error)=>{
                console.log(error.message);
            })
        }
      },
    mounted() {
        this.getRequest()
        this.GetSites()
        this.GetCategories()
    },
    computed: {
      ...mapGetters({
                isLoggedIn: 'isLoggedIn',
                user: 'user'})
    }
  }
</script>

<style scoped>
p {
  font-size: 14xp;
  margin-bottom: 10px;
  font: "Montserrat";
}
h3 {
  font: "Montserrat";
  font-weight: bold;
}
h4 {
  font: "Montserrat";
  font-weight: bold;
}
.form-group{
  margin-top: 10px !important;
}
.d-flex{
  margin-top: 20px;
}
.btn{
  margin-left: 10px;
  width: 70px !important;
  margin-bottom: 25px !important;
}
.photo-container{
    width: 196px;
    height: 196px;
    background: black;
    margin-right: 20px !important;
    padding: 10px;
}
.photo{
    width: 174px;
    height: 172px;
}
body {
  display: flex !important ;
  flex-direction: column !important ;
  background: #F1F1F1 !important ;
}
.white-panel {
  margin-top: 20px;
  background: white !important ;
  padding: 20px 40px 20px 40px !important;
  border-radius: 10px !important;
}
.container {
  font-size: 13px !important;
}
</style>

<template>
<body>
  <div class="cont">
    <b-row>
        <h3>Daftar Aset</h3>
    </b-row>
    <b-row>
        <p>Daftar aset yang tercatat di sistem informasi manajemen aset</p>
    </b-row>
    <div class="white-panel">
        <b-row>
            <div class="button-padding">
            <b-col lg="auto">
                <b-button pill to="/asset-filter" variant="success" size="sm">
                    <b-icon icon="search"></b-icon>
                    Filter Data
                </b-button>
            </b-col>
            </div>
        </b-row>
        <b-row>
            <b-table
                id="asset_table"
                :items="list_element"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
            >
                <template #cell(actions)="row">
                    <b-button-group>
                        <b-button :to="{name:'AssetDetail', params: { id:row.item.id }}" variant="warning">
                            <b-icon icon="eye-fill" variant="light"></b-icon>
                        </b-button>
                        <b-button v-if="user.type !== 0" :to="{name:'AssetEdit', params: { id:row.item.id }}" variant="success">
                            <b-icon icon="pencil-square"></b-icon>
                        </b-button>
                        <b-button v-if="user.type !== 0" @click="Delete(row.item.id)" variant="danger">
                            <b-icon icon="trash-fill"></b-icon>
                        </b-button>
                    </b-button-group>
                </template>
            </b-table>
        </b-row>
        <div class="overflow-auto">
            <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="asset_table"
            ></b-pagination>
        </div>
    </div>
  </div>

</body>
</template>

<script>
    import axios from "axios"
    import {mapGetters} from 'vuex'

    export default {
        props: ['type', 'search', 'site_id', 'sortOn', 'sortFrom', 'amount', 'valueRangeMin', 'valueRangeMax', 'dateRangeMin', 'dateRangeMax'],
        data() {
            return {
                fields: [
                    { key:'id', label:'ID', sortable: true, sortDirection: 'desc', thClass: 'bg-dark text-white', tdClass: 'border'},
                    { key:'asset_name', label:'Nama Aset',  sortable: true, thClass: 'bg-dark text-white', tdClass: 'border'},
                    { key:'type', label:'Kategori Aset',  sortable: true, thClass: 'bg-dark text-white', tdClass: 'border'},
                    { key:'purchase_date', label:'Tanggal Perolehan',  sortable: true, thClass: 'bg-dark text-white', tdClass: 'border'},
                    { key:'site.name', label:'Site',  sortable: true, thClass: 'bg-dark text-white', tdClass: 'border'},
                    { key:'cost_individual', label:'Harga Per Unit',  sortable: true, thClass: 'bg-dark text-white', tdClass: 'border'},
                    { key:'unit', label:'Jumlah',  sortable: true, thClass: 'bg-dark text-white', tdClass: 'border'},
                    { key:'actions', label:'Tindakan', thClass: 'bg-dark text-white', tdClass: 'border', class: "text-center"}           
                ],
                list_element: [],
                totalRows: 10,
                currentPage: 1,
                perPage: 10,
                sortBy: 'id',
                sortDesc: false,
                num: 0
            }
        },
        methods: {
            GetAllAssets() {
                this.list_element = []
                var link = 'v1/assets?eagerLoad=true&itemsPerPage=9999&filter='
                if (this.type) { // except furniture
                    if (this.type.match("Furniture")) {
                        this.type = "Furniture and Electronics"
                    }
                    link = link + "type=" + this.type.toString() + ","
                }
                if (this.site_id) {
                    link = link + "site_id=" + this.site_id.toString() +","
                }
                if (this.valueRangeMin) {
                    link = link + "cost_total>=" + this.valueRangeMin.toString() +","
                }
                if (this.valueRangeMax > 0) {
                    link = link + "cost_total<=" + this.valueRangeMax.toString() +","
                }
                if (this.dateRangeMin) {
                    link = link + "purchase_date>=" + this.dateRangeMin.toString() +","
                }
                if (this.dateRangeMax) {
                    link = link + "purchase_date<=" + this.dateRangeMax.toString() +","
                }
                if (this.search) {
                    link = link + "&query=" + this.search.toString()
                }

                axios
                .get(link)
                .then(response => {
                    this.list_element = response.data.data;})
                .catch (error => {console.log(error)})
            },
            Delete(id){
                var link = 'v1/assets/' + id.toString()
                axios
                .delete(link)
                .then((response)=>{
                    this.GetAllAssets()
                })
            }
            
        },
        mounted() {
            this.GetAllAssets()

            if (this.amount) {
                this.perPage = this.amount
            }
            if (this.sortOn) {
                this.sortBy = this.sortOn
                if (this.sortFrom) {
                    this.sortDesc = this.sortFrom.match('desc')
                }
            }
        },
        computed: {
            rows() {
                return this.list_element.length
            },
            ...mapGetters({
                isLoggedIn: 'isLoggedIn',
                user: 'user'})
        }
    }
</script>

<style scoped>
p {
  font-size: 14px;
  margin-bottom: 10px;
  font: "Montserrat";
}
.d-flex{
  margin-top: 20px;
  margin-bottom: 30px;
}
h3 {
  font: "Montserrat";
  font-weight: bold;
}
.btn{
  margin-left: 10px;
}
.form-group{
  margin-top: 10px;
}

#detail {
  text-align: center;
  padding: 5px;
}

 body {
     display: flex !important ;
     flex-direction: column !important ;
     background: #F1F1F1 !important ;
 }
 .white-panel {
     margin-top: 20px;
     background: white !important ;
     padding: 20px 40px 0px 40px !important;
     border-radius: 10px !important;
 }
 .button-padding {
   padding-bottom: 13px !important;
   padding-left: 0px !important;
}
.cont {
  font-size: 14px !important;
  min-height: 100vh !important;
}
</style>
<template>
<div>
<!--Kategori == Tanah-->
                <div v-if="form.type=== 'Land'">
                    <h6>Lokasi</h6>
                    <!--Baris 1-->
                    <div class="row">
                    <!--Form Kiri-->
                    <div class="col-lg-6">

                        <!--Jalan-->
                        <div class="form-group row">
                        <label for="input-road" class="col-sm-3 col-form-label">Jalan</label>
                        <div class="col-sm-9">
                            <b-form-input v-if="isEdit"
                                id="input-road"
                                v-model="form.assetable.street"
                                required
                                size="sm"
                            ></b-form-input>
                            <b-form-input v-else
                                id="input-road"
                                v-model="form.assetable.street"
                                required
                                disabled
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.street" class="failed-text font-montserrat">{{error_message.street}}</p>
                        </div>
                        </div>

                        <!--Nomor Alamat-->
                        <div class="form-group row">
                        <label for="input-number" class="col-sm-3 col-form-label">Nomor Alamat</label>
                        <div class="col-sm-9">
                            <b-form-input v-if="isEdit"
                                id="input-number"
                                v-model="form.assetable.number"
                                required
                                size="sm"
                            ></b-form-input>
                            <b-form-input v-else
                                id="input-number"
                                v-model="form.assetable.number"
                                required
                                size="sm"
                                disabled
                            ></b-form-input>
                            <p v-if="failed.number" class="failed-text font-montserrat">{{error_message.number}}</p>
                        </div>
                        </div>

                        <!--RT-->
                        <div class="form-group row">
                        <label for="input-rt" class="col-sm-3 col-form-label">RT</label>
                        <div class="col-sm-9">
                            <b-form-input v-if="isEdit"
                                id="input-rt"
                                v-model="form.assetable.rt"
                                required
                                size="sm"
                            ></b-form-input>
                            <b-form-input v-else
                                id="input-rt"
                                v-model="form.assetable.rt"
                                required
                                disabled
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.rt" class="failed-text font-montserrat">{{error_message.rt}}</p>
                        </div>
                        </div>

                        <!--RW-->
                        <div class="form-group row">
                        <label for="input-rw" class="col-sm-3 col-form-label">RW</label>
                        <div class="col-sm-9">
                            <b-form-input v-if="isEdit"
                                id="input-rw"
                                v-model="form.assetable.rw"
                                required
                                size="sm"
                            ></b-form-input>
                            <b-form-input v-else
                                id="input-rw"
                                v-model="form.assetable.rw"
                                required
                                disabled
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.rw" class="failed-text font-montserrat">{{error_message.rw}}</p>
                        </div>
                        </div>

                    </div>

                    <!--Form Kanan-->
                    <div class="col-lg-6">

                        <!--Desa-->
                        <div class="form-group row">
                        <label for="input-desa" class="col-sm-3 col-form-label">Desa / Kelurahan</label>
                        <div class="col-sm-9">
                            <b-form-input v-if="isEdit"
                                id="input-desa"
                                v-model="form.assetable.village"
                                required
                                size="sm"
                            ></b-form-input>
                            <b-form-input v-else
                                id="input-desa"
                                v-model="form.assetable.village"
                                required
                                disabled
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.village" class="failed-text font-montserrat">{{error_message.village}}</p>
                        </div>
                        </div>

                        <!--Distrik?-->
                        <div class="form-group row">
                        <label for="input-distrik" class="col-sm-3 col-form-label">Kecamatan</label>
                        <div class="col-sm-9">
                            <b-form-input v-if="isEdit"
                                id="input-distrik"
                                v-model="form.assetable.district"
                                required
                                size="sm"
                            ></b-form-input>
                            <b-form-input v-else
                                id="input-distrik"
                                v-model="form.assetable.district"
                                required
                                disabled
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.district" class="failed-text font-montserrat">{{error_message.district}}</p>
                        </div>
                        </div>

                        <!--Kota-->
                        <div class="form-group row">
                        <label for="input-city" class="col-sm-3 col-form-label">Kota / Kabupaten</label>
                        <div class="col-sm-9">
                            <b-form-input v-if="isEdit"
                                id="input-city"
                                v-model="form.assetable.city"
                                required
                                size="sm"
                            ></b-form-input>
                            <b-form-input v-else
                                id="input-city"
                                v-model="form.assetable.city"
                                required
                                disabled
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.city" class="failed-text font-montserrat">{{error_message.city}}</p>
                        </div>
                        </div>

                        <!--Provinsi-->
                        <div class="form-group row">
                        <label for="input-provinsi" class="col-sm-3 col-form-label">Provinsi</label>
                        <div class="col-sm-9">
                            <b-form-input v-if="isEdit"
                                id="input-provinsi"
                                v-model="form.assetable.province"
                                required
                                size="sm"
                            ></b-form-input>
                            <b-form-input v-else
                                id="input-provinsi"
                                v-model="form.assetable.province"
                                required
                                disabled
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.province" class="failed-text font-montserrat">{{error_message.province}}</p>
                        </div>
                        </div>

                    </div>
                    </div>
                
                    <!--Baris 2-->
                    <h6>Lainnya</h6>
                    <div class="row">
                    <!--Kolom Kiri-->
                    <div class="col-lg-6">

                        <!--No. Pensil-->
                        <div class="form-group row">
                        <label for="input-pensil" class="col-sm-3 col-form-label">No. Persil</label>
                        <div class="col-sm-9">
                            <b-form-input v-if="isEdit"
                                id="input-pensil"
                                v-model="form.assetable.persil_number"
                                required
                                size="sm"
                            ></b-form-input>
                            <b-form-input v-else
                                id="input-pensil"
                                v-model="form.assetable.persil_number"
                                required
                                disabled
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.persil_number" class="failed-text font-montserrat">{{error_message.persil_number}}</p>
                        </div>
                        </div>

                        <!--No. Sertifikat-->
                        <div class="form-group row">
                        <label for="input-noCert" class="col-sm-3 col-form-label">No. Sertifikat</label>
                        <div class="col-sm-9">
                            <b-form-input v-if="isEdit"
                                id="input-noCert"
                                v-model="form.assetable.certificate_number"
                                required
                                size="sm"
                            ></b-form-input>
                            <b-form-input v-else
                                id="input-noCert"
                                v-model="form.assetable.certificate_number"
                                required
                                disabled
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.certificate_number" class="failed-text font-montserrat">{{error_message.certificate_number}}</p>
                        </div>
                        </div>
                    </div>

                    <!--Kolom Kanan-->
                    <div class="col-lg-6">

                        <!--NIB-->
                        <div class="form-group row">
                        <label for="input-nib" class="col-sm-3 col-form-label">NIB</label>
                        <div class="col-sm-9">
                            <b-form-input v-if="isEdit"
                                id="input-nib"
                                v-model="form.assetable.nib_number"
                                required
                                size="sm"
                            ></b-form-input>
                            <b-form-input v-else
                                id="input-nib"
                                v-model="form.assetable.nib_number"
                                required
                                disabled
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.nib_number" class="failed-text font-montserrat">{{error_message.nib_number}}</p>
                        </div>
                        </div>

                        <!--Luas-->
                        <div class="form-group row">
                        <label for="input-luas" class="col-sm-3 col-form-label">Luas</label>
                        <div class="col-sm-9">
                            <b-form-input v-if="isEdit"
                                id="input-luas"
                                v-model="form.assetable.width"
                                required
                                type="number"
                                size="sm"
                            ></b-form-input>
                            <b-form-input v-else
                                id="input-luas"
                                v-model="form.assetable.width"
                                required
                                type="number"
                                disabled
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.width" class="failed-text font-montserrat">{{error_message.width}}</p>
                        </div>
                        </div>
                    </div>
                    </div>

                    <!--Baris 3-->
                    <h6>Deskripsi</h6>
                    <b-form-textarea v-if="isEdit"
                        id="textarea"
                        v-model="form.assetable.description"
                        placeholder="Enter something..."
                        rows="3"
                        max-rows="6"
                        size="sm"
                    ></b-form-textarea>
                    <b-form-textarea v-else
                        id="textarea"
                        v-model="form.assetable.description"
                        placeholder="Enter something..."
                        rows="3"
                        max-rows="6"
                        disabled
                        size="sm"
                    ></b-form-textarea>
                    <p v-if="failed.description" class="failed-text font-montserrat">{{error_message.description}}</p>

                </div>

                <!--Kategori == Bangunan-->
                <div v-if="form.type=== 'Building'">
                    <!--Baris 1-->
                    <h6>Lokasi</h6>
                    <div class="row-xxl-6">
                    <!--Kolom Kiri-->
                    <div class="col">

                        <!--Alamat-->
                        <div class="form-group row">
                        <label for="input-address" class="col-sm-2 col-form-label">Alamat</label>
                        <div class="col-sm-10">
                            <b-form-input v-if="isEdit"
                                id="input-address"
                                v-model="form.assetable.address"
                                required
                                size="sm"
                            ></b-form-input>
                            <b-form-input v-else
                                id="input-address"
                                v-model="form.assetable.address"
                                required
                                disabled
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.address" class="failed-text font-montserrat">{{error_message.address}}</p>
                        </div>
                        </div>

                    </div>
                    </div>
                    
                    <!--Baris 2-->
                    <h6>Lainnya</h6>
                    <div class="row">
                    <!--Kolom Kiri-->
                    <div class="col-lg-6">

                        <!--Jumlah Lantai-->
                        <div class="form-group row">
                        <label for="input-floor" class="col-sm-4 col-form-label">Jumlah Lantai</label>
                        <div class="col-sm-8">
                            <b-form-input v-if="isEdit"
                                id="input-floor"
                                v-model="form.assetable.num_of_floors"
                                required
                                type="number"
                                size="sm"
                            ></b-form-input>
                            <b-form-input v-else
                                id="input-floor"
                                v-model="form.assetable.num_of_floors"
                                required
                                type="number"
                                disabled
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.num_of_floors" class="failed-text font-montserrat">{{error_message.num_of_floors}}</p>
                        </div>
                        </div>

                        <!--Luas Bangunan-->
                        <div class="form-group row">
                        <label for="input-luas" class="col-sm-4 col-form-label">Luas Bangunan</label>
                        <div class="col-sm-8">
                            <b-form-input v-if="isEdit"
                                id="input-luas"
                                v-model="form.assetable.building_area"
                                required
                                type="number"
                                size="sm"
                            ></b-form-input>
                            <b-form-input v-else
                                id="input-luas"
                                v-model="form.assetable.building_area"
                                required
                                type="number"
                                disabled
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.building_area" class="failed-text font-montserrat">{{error_message.building_area}}</p>
                        </div>
                        </div>

                        <!--Added Benefit Value-->
                        <div class="form-group row">
                        <label for="input-benefit-value" class="col-sm-4 col-form-label">Added Benefit Value</label>
                        <div class="col-sm-8">
                            <b-form-input v-if="isEdit"
                                id="input-benefit-value"
                                v-model="form.assetable.added_benefit_value"
                                required
                                type="number"
                                size="sm"
                            ></b-form-input>
                            <b-form-input v-else
                                id="input-benefit-value"
                                v-model="form.assetable.added_benefit_value"
                                required
                                type="number"
                                disabled
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.added_benefit_value" class="failed-text font-montserrat">{{error_message.added_benefit_value}}</p>
                        </div>
                        </div>

                    </div>

                    <!--Kolom Kanan-->
                    <div class="col-lg-6">

                        <!--Tahun Dibangun-->
                        <div class="form-group row">
                        <label for="input-build-year" class="col-sm-4 col-form-label">Tahun Dibangun</label>
                        <div class="col-sm-8">
                            <b-form-input v-if="isEdit"
                                id="input-build-year"
                                v-model="form.assetable.build_year"
                                required
                                type="number"
                                size="sm"
                            ></b-form-input>
                            <b-form-input v-else
                                id="input-build-year"
                                v-model="form.assetable.build_year"
                                required
                                type="number"
                                disabled
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.build_year" class="failed-text font-montserrat">{{error_message.build_year}}</p>
                        </div>
                        </div>

                        <!--Tahun Penggunaan-->
                        <div class="form-group row">
                        <label for="input-usage-year" class="col-sm-4 col-form-label">Tahun Penggunaan</label>
                        <div class="col-sm-8">
                            <b-form-input v-if="isEdit"
                                id="input-usage-year"
                                v-model="form.assetable.usage_year"
                                required
                                type="number"
                                size="sm"
                            ></b-form-input>
                            <b-form-input v-else
                                id="input-usage-year"
                                v-model="form.assetable.usage_year"
                                required
                                type="number"
                                disabled
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.usage_year" class="failed-text font-montserrat">{{error_message.usage_year}}</p>
                        </div>
                        </div>

                    </div>
                    </div>

                </div>

                <!--Kategori == Kendaraan -->
                <div v-if="form.type=== 'Vehicle'">
                    <!--Baris 1-->
                    <h6>Spesifikasi</h6>
                    <div class="row">
                    <!--Kolom Kiri-->
                    <div class="col-lg-6">

                        <!--No. Mesin-->
                        <div class="form-group row">
                        <label for="input-engine" class="col-sm-4 col-form-label">Nomor Mesin</label>
                        <div class="col-sm-8">
                            <b-form-input v-if="isEdit"
                                id="input-engine"
                                v-model="form.assetable.machine_number"
                                required
                                size="sm"
                            ></b-form-input>
                            <b-form-input v-else
                                id="input-engine"
                                v-model="form.assetable.machine_number"
                                required
                                disabled
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.machine_number" class="failed-text font-montserrat">{{error_message.machine_number}}</p>
                        </div>
                        </div>

                        <!--No. Rangka-->
                        <div class="form-group row">
                        <label for="input-chassis" class="col-sm-4 col-form-label">Nomor Rangka</label>
                        <div class="col-sm-8">
                            <b-form-input v-if="isEdit"
                                id="input-chassis"
                                v-model="form.assetable.body_number"
                                required
                                size="sm"
                            ></b-form-input>
                            <b-form-input v-else
                                id="input-chassis"
                                v-model="form.assetable.body_number"
                                required
                                disabled
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.body_number" class="failed-text font-montserrat">{{error_message.body_number}}</p>
                        </div>
                        </div>

                        <!--Silinder-->
                        <div class="form-group row">
                        <label for="input-cylinder" class="col-sm-4 col-form-label">Isi Silinder</label>
                        <div class="col-sm-8">
                            <b-form-input v-if="isEdit"
                                id="input-cylinder"
                                v-model="form.assetable.cc"
                                required
                                type="number"
                                size="sm"
                            ></b-form-input>
                            <b-form-input v-else
                                id="input-cylinder"
                                v-model="form.assetable.cc"
                                required
                                type="number"
                                disabled
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.cc" class="failed-text font-montserrat">{{error_message.cc}}</p>
                        </div>
                        </div>

                    </div>

                    <!--Kolom Kanan-->
                    <div class="col-lg-6">

                        <!--Tahun Pembuatan-->
                        <div class="form-group row">
                        <label for="input-year" class="col-sm-4 col-form-label">Tahun Pembuatan</label>
                        <div class="col-sm-8">
                            <b-form-input v-if="isEdit"
                                id="input-year"
                                v-model="form.assetable.manufacture_year"
                                required
                                type="number"
                                size="sm"
                            ></b-form-input>
                            <b-form-input v-else
                                id="input-year"
                                v-model="form.assetable.manufacture_year"
                                required
                                type="number"
                                disabled
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.manufacture_year" class="failed-text font-montserrat">{{error_message.manufacture_year}}</p>
                        </div>
                        </div>

                        <!--Brand-->
                        <div class="form-group row">
                        <label for="input-merk" class="col-sm-4 col-form-label">Jenis / Merk</label>
                        <div class="col-sm-8">
                            <b-form-input v-if="isEdit"
                                id="input-merk"
                                v-model="form.assetable.brand"
                                required
                                size="sm"
                            ></b-form-input>
                            <b-form-input v-else
                                id="input-merk"
                                v-model="form.assetable.brand"
                                required
                                disabled
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.brand" class="failed-text font-montserrat">{{error_message.brand}}</p>
                        </div>
                        </div>

                        <!--Jumlah Roda-->
                        <div class="form-group row">
                        <label for="input-tires" class="col-sm-4 col-form-label">Jumlah Roda</label>
                        <div class="col-sm-8">
                            <b-form-input v-if="isEdit"
                                id="input-tires"
                                v-model="form.assetable.num_tires"
                                required
                                type="number"
                                size="sm"
                            ></b-form-input>
                            <b-form-input v-else
                                id="input-tires"
                                v-model="form.assetable.num_tires"
                                required
                                type="number"
                                disabled
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.num_tires" class="failed-text font-montserrat">{{error_message.num_tires}}</p>
                        </div>
                        </div>

                    </div>
                    </div>
                    
                    <!--Baris 2-->
                    <h6>Bukti Kepemilikan</h6>
                    <div class="row">
                    <!--Kolom Kiri-->
                    <div class="col-lg-6">

                        <!--BPKB-->
                        <div class="form-group row">
                        <label for="input-bkpb" class="col-sm-4 col-form-label">No. BPKB</label>
                        <div class="col-sm-8">
                            <b-form-input v-if="isEdit"
                                id="input-bpkb"
                                v-model="form.assetable.bpkb_number"
                                required
                                size="sm"
                            ></b-form-input>
                            <b-form-input v-else
                                id="input-bpkb"
                                v-model="form.assetable.bpkb_number"
                                required
                                disabled
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.bpkb_number" class="failed-text font-montserrat">{{error_message.bpkb_number}}</p>
                        </div>
                        </div>

                    </div>

                    <!--Kolom Kanan-->
                    <div class="col-lg-6">

                        <!--Police Number-->
                        <div class="form-group row">
                        <label for="input-police" class="col-sm-4 col-form-label">No. Polis</label>
                        <div class="col-sm-8">
                            <b-form-input v-if="isEdit"
                                id="input-police"
                                v-model="form.assetable.police_number"
                                required
                                size="sm"
                            ></b-form-input>
                            <b-form-input v-else
                                id="input-police"
                                v-model="form.assetable.police_number"
                                required
                                size="sm"
                                disabled
                            ></b-form-input>
                            <p v-if="failed.police_number" class="failed-text font-montserrat">{{error_message.police_number}}</p>
                        </div>
                        </div>
                    </div>

                    </div>

                    <!--Baris 3-->
                    <h6>Lainnya</h6>
                    <div class="row">
                    <!--Kolom Kiri-->
                    <div class="col-lg-6">

                        <!--Sumber Pendanaan-->
                        <div class="form-group row">
                        <label for="input-source" class="col-sm-4 col-form-label">Sumber Pendanaan</label>
                        <div class="col-sm-8">
                            <b-form-input v-if="isEdit"
                                id="input-source"
                                v-model="form.assetable.fund_source"
                                required
                                size="sm"
                            ></b-form-input>
                            <b-form-input v-else
                                id="input-source"
                                v-model="form.assetable.fund_source"
                                required
                                disabled
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.fund_source" class="failed-text font-montserrat">{{error_message.fund_source}}</p>
                        </div>
                        </div>

                    </div>

                    <!--Kolom Kanan-->
                    <div class="col-lg-6">

                        <!--Tanggung Jawab-->
                        <div class="form-group row">
                        <label for="input-charge" class="col-sm-4 col-form-label">Tanggung Jawab</label>
                        <div class="col-sm-8">
                            <b-form-input v-if="isEdit"
                                id="input-charge"
                                v-model="form.assetable.in_charge"
                                required
                                size="sm"
                            ></b-form-input>
                            <b-form-input v-else
                                id="input-charge"
                                v-model="form.assetable.in_charge"
                                required
                                disabled
                                size="sm"
                            ></b-form-input>
                            <p v-if="failed.in_charge" class="failed-text font-montserrat">{{error_message.in_charge}}</p>
                        </div>
                        </div>

                    </div>

                    </div>
                </div>

                <!--Kategori == Furnitur dan Elektronik-->
                <div v-if="form.type=== 'Furniture and Electronics'">
                    <!--Baris 1-->
                    <div class="row">
                    <!--Kolom Kiri-->
                        <div class="col-lg-6">

                            <!--Brand-->
                            <div class="form-group row">
                                <label for="input-merk" class="col-sm-3 col-form-label">Jenis / Merk</label>
                                <div class="col-sm-9">
                                    <b-form-input v-if="isEdit"
                                        id="input-merk"
                                        v-model="form.assetable.brand"
                                        required
                                        size="sm"
                                    ></b-form-input>
                                    <b-form-input v-else
                                        id="input-merk"
                                        v-model="form.assetable.brand"
                                        required
                                        disabled
                                        size="sm"
                                    ></b-form-input>
                                    <p v-if="failed.brand" class="failed-text font-montserrat">{{error_message.brand}}</p>
                                </div>
                            </div>

                            <!--Kategori-->
                            <div>
                                <div class="form-group row">
                                    <label for="input-category" class="col-sm-3 col-form-label">Kategori</label>
                                    <div class="col-sm-9">
                                        <b-form-select v-if="isEdit"
                                            id="input-category"
                                            v-model="form.assetable.category.id"
                                            required
                                            size="sm"
                                            style="width: 100%; height: 100%"
                                        >
                                        <b-form-select-option v-for="item in this.categories" :value="item.id">{{item.name}}</b-form-select-option>
                                        </b-form-select>
                                        <b-form-select v-else
                                            id="input-category"
                                            v-model="form.assetable.category.id"
                                            required
                                            disabled
                                            size="sm"
                                            style="width: 100%; height: 100%"
                                        >
                                        <b-form-select-option v-for="item in this.categories" :value="item.id">{{item.name}}</b-form-select-option>
                                        </b-form-select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-3"></div>
                                    <div class="col-sm-9 m-1">
                                        <p v-if="failed.category_id" class="failed-text font-montserrat">{{error_message.category_id}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--Kolom Kanan-->
                        <div class="col-lg-6">

                            <!--Sumber-->
                            <div class="form-group row">
                                <label for="input-source" class="col-sm-3 col-form-label">Sumber</label>
                                <div class="col-sm-9">
                                    <b-form-input v-if="isEdit"
                                        id="input-source"
                                        v-model="form.assetable.source"
                                        required
                                        size="sm"
                                    ></b-form-input>
                                    <b-form-input v-else
                                        id="input-source"
                                        v-model="form.assetable.source"
                                        required
                                        disabled
                                        size="sm"
                                    ></b-form-input>
                                    <p v-if="failed.source" class="failed-text font-montserrat">{{error_message.source}}</p>
                                </div>
                            </div>

                            <!--Tanggung Jawab-->
                            <div class="form-group row">
                                <label for="input-charge" class="col-sm-3 col-form-label">Tanggung Jawab</label>
                                <div class="col-sm-9">
                                    <b-form-input v-if="isEdit"
                                        id="input-charge"
                                        v-model="form.assetable.in_charge"
                                        required
                                        size="sm"
                                    ></b-form-input>
                                    <b-form-input v-else
                                        id="input-charge"
                                        v-model="form.assetable.in_charge"
                                        required
                                        disabled
                                        size="sm"
                                    ></b-form-input>
                                    <p v-if="failed.in_charge" class="failed-text font-montserrat">{{error_message.in_charge}}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
</div>
</template>

<script>
export default {
    name: "AssetDetailsContainer",
    props: ['form', 'categories', 'isEdit', 'error_message', 'failed'],
}
</script>

<style scoped>
.failed-text{
    font-size: 14px;
  margin-top: 10px;
  color: red;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

.font-montserrat{
  font-family: "Montserrat";
}

p::first-letter{
  text-transform: capitalize;
}

.form-group {
    padding-bottom: 8px
}
</style>
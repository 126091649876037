<template>
<body>
  <b-container>
    <b-row>
        <h3>Daftar Request Pending</h3>
    </b-row>
    <b-row>
        <p>Daftar requests yang diminta oleh pengguna yang belum diterima</p>
    </b-row>
    <div class="white-panel">
        <b-row>
            <b-table v-if="request.length > 0"
                id="site_table"
                :items="request"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
            >
                <template #cell(actions)="row">
                    <b-button-group>
                        <b-button v-if="(row.item.type=='delete' && row.item.model=='asset')" @click="toDetailDelete(row.item.id)" variant="warning">
                            <b-icon icon="eye-fill" variant="light"></b-icon>
                        </b-button>
                        <b-button v-else-if="row.item.model=='document' || row.item.model=='gallery'" @click="toDetailDocument(row.item.id)" variant="warning">
                            <b-icon icon="eye-fill" variant="light"></b-icon>
                        </b-button>
                        <b-button v-else @click="toDetailAdd(row.item.id)" variant="warning">
                            <b-icon icon="eye-fill" variant="light"></b-icon>
                        </b-button>
                        <b-button v-if="user.type === 2" @click="onDelete(row.item.id)" variant="danger">
                            <b-icon icon="trash-fill"></b-icon>
                        </b-button>
                    </b-button-group>
                </template>
            </b-table>
            <h5 v-else>TIDAK ADA REQUEST</h5>
        </b-row>
        <div class="overflow-auto">
            <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="site_table"
            ></b-pagination>
        </div>
    </div>
  </b-container>
</body>
</template>

<script>
    import axios from "axios"
    import {mapGetters} from 'vuex'
    export default {
        props: ['search', 'sortOn', 'sortFrom', 'amount'],
        data() {
            return {
                fields: [
                { key:'id', label:'ID', sortable: true, sortDirection: 'desc', thClass: 'bg-dark text-white', tdClass: 'border', class: 'text-center'},
                { key:'type', label:'Tipe',  sortable: true, thClass: 'bg-dark text-white', tdClass: 'border', class: 'text-center'},
                { key:'model', label:'Target Data',  sortable: true, thClass: 'bg-dark text-white', tdClass: 'border', class: 'text-center'},
                // { 
                //     key:'body', label:'Atribut',  sortable: true, 
                //     formatter: value => {
                //         return Object.keys(value).join(', ');
                //     },
                //     thClass: 'bg-dark text-white', tdClass: 'border'
                // },
                { key:'status', label:'status',  sortable: true, thClass: 'bg-dark text-white', tdClass: 'border', class: 'text-center'},
                { key:'actions', label:'Tindakan', thClass: 'bg-dark text-white', tdClass: 'border', class: 'text-center' }
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                sortBy: 'id',
                sortDesc: false,
                request: '',
                parse_request: ''
            }
        },
        mounted() {
            this.GetRequest();

            if (this.amount) {
                this.perPage = this.amount
            }
            if (this.sortOn) {
                this.sortBy = this.sortOn
                if (this.sortFrom) {
                    this.sortDesc = this.sortFrom.match('desc')
                }
            }
        },
        computed: {
            rows() {
                return this.request.length
            },
            ...mapGetters({
                isLoggedIn: 'isLoggedIn',
                user: 'user'})
        },
        methods: {
            GetRequest(){
                var link = 'v1/confirmations?&itemsPerPage=9999'
                if (this.search) {
                    link = link + "&query=" + this.search.toString()
                }
                axios.get(link)
                .then(response => {
                    this.request = response.data.data;
                    for (let i = 0; i < this.request.length; i++) {
                        this.request[i].body = JSON.parse(this.request[i].body);
                    }
                });
                
            },
            onDelete(id){
                axios.delete('v1/confirmations/' + id)
                .then((response)=>{
                    this.GetRequest();
                })
            },
            toDetailDelete(id){
                this.$router.push({ path : '/request-delete/'+id });
            },
            toDetailAdd(id){
                this.$router.push({ path : '/request/'+id });
            },
            toDetailDocument(id){
                this.$router.push({ path : '/request-document/'+id });
            },
            accept(id){
                axios.get('v1/confirmations/'+id+'/accept')
                .then((response)=>{
                    this.GetRequest();
                })
            },
            reject(id){
                axios.get('v1/confirmations/'+id+'/reject')
                .then((response)=>{
                    this.GetRequest();
                })
            }
        }

    }
</script>

<style scoped>
p {
  font-size: 14xp;
  margin-bottom: 10px;
  font: "Montserrat";
}
.d-flex{
  margin-top: 20px;
  margin-bottom: 30px;
}
h3 {
  font: "Montserrat";
  font-weight: bold;
}
.btn{
  margin-left: 10px;
}
.form-group{
  margin-top: 10px;
}

#detail {
  text-align: center;
  padding: 5px;
}

 body {
     display: flex !important ;
     flex-direction: column !important ;
     background: #F1F1F1 !important ;
 }
 .white-panel {
     margin-top: 20px;
     background: white !important ;
     padding: 20px 40px 20px 40px !important;
     border-radius: 10px !important;
 }
 .button-padding {
   padding-bottom: 13px !important;
   padding-left: 0px !important;
}
.container {
  font-size: 14px !important
}

h5 {
        text-align: center;
    }
</style>
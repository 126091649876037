<template>
    <div id="line">
        <canvas id="line-chart" ></canvas>
    </div>
</template>

<script>
import {Chart} from 'chart.js'

export default{
    name: 'LineChart',
    props: [
        'lineLabel',
        'lineChartData',
    ],
    methods: {
        renderChart(){
            const ctx = document.getElementById('line-chart')
            new Chart(ctx, {
                    type: "line",
                    data: {
                        labels : this.lineLabel,
                        datasets : [
                            {
                                label: 'Total Nilai Aset',
                                data: this.lineChartData,
                                fill: false,
                                borderColor: '#20F520',
                                backgroundColor: '#121212',
                                borderWidth: 2
                            }
                        ],
                    },
                    options: {
                        title: {
                            display: true,
                            text: 'Nilai Aset dari Tahun ke Tahun',
                            fontSize: 24,
                            fontColor: 'black',
                            fontFamily: 'Montserrat'
                        },
                        scales: {
                            yAxes: [{
                                scaleLabel:{
                                    display: true,
                                    labelString: 'Total Nilai (Rupiah)',
                                    fontColor: 'black',
                                    fontFamily: 'Montserrat',
                                },
                                ticks: {
                                    beginAtZero: false,
                                    callback: function(value, index, ticks){
                                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                    }
                                },
                                gridLines: {
                                    display: true
                                }
                            }],
                            xAxes: [{
                                scaleLabel:{
                                    display: true,
                                    labelString: 'Tahun',
                                    padding: 5,
                                    fontColor: 'black',
                                    fontFamily: 'Montserrat',
                                },
                                gridLines: {
                                    display: false
                                }
                            }]
                        },
                        legend: {
                            display: true
                        },
                        responsive: true,
                        maintainAspectRatio: true,
                    }
            })
        }
    },
    mounted(){
        this.renderChart()
    },
    watch: {
        'lineLabel' (){
            document.querySelector("#line").innerHTML = '<canvas id="line-chart"></canvas>'
            this.renderChart()
        },
        'lineChartData' (){
            document.querySelector("#line").innerHTML = '<canvas id="line-chart"></canvas>'
            this.renderChart()
        }
    }
}
</script>
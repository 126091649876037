<template>
  <div id="app">
    <side-bar-component v-if="isLoggedIn"/>
    <unauthorized v-if="!isLoggedIn"/>
  </div>
</template>

<script>
// @ is an alias to /src
import SideBarComponent from './components/SideBarComponent.vue'
import Login from './views/Login.vue'
import Unauthorized from './views/Unauthorized.vue'
import {mapGetters} from 'vuex'


export default {
  name: 'App',
  components: {
    SideBarComponent,
    Login,
    Unauthorized,
  },
  computed:{
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
    })
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
#app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
//    padding: 30px;
font-family: 'Montserrat', sans-serif;
background-color: #f1f1f1;
min-height:100vh;
// height: 1500px;
}

template{
  background-color: #f1f1f1;
}


// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>

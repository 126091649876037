<template>
  <div class="cont">
    <h3>Detail Akun</h3>
    <div class="form_account">
    <b-form v-if="show">
      <div class="row">
      <div class="col">
        <b-form-group id="input-group-2" label="Nama" label-for="input-2">
          <b-form-input
            id="input-2"
            v-model="form.name"
            readonly
            required
          ></b-form-input>
        </b-form-group>
      </div>

      <div class="col">
        <b-form-group id="input-group-3" label="Tipe" label-for="input-3">
          <div v-if="form.type===0">
            <b-form-input
            id="input-3"
            placeholder="Masa Umum Salman"
            disabled
            required
          ></b-form-input>
          </div>
          <div v-if="form.type===1">
            <b-form-input
            id="input-3"
            placeholder="Anggota Biasa"
            disabled
            required
          ></b-form-input>
          </div>
          <div v-if="form.type===2">
            <b-form-input
            id="input-3"
            placeholder="Anggota Utama"
            disabled
            required
          ></b-form-input>
          </div>
        </b-form-group>
      </div>
      </div>

      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Username</label>
        <div class="col-sm-10">
          <b-form-input
          id="input-2"
          v-model="form.username"
          required
          readonly
        ></b-form-input>
        </div>
      </div>

      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Jabatan</label>
        <div class="col-sm-10">
          <b-form-input
          id="input-2"
          v-model="form.position"
          required
          readonly
        ></b-form-input>
        </div>
      </div>

      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Departemen</label>
        <div class="col-sm-10">
          <b-form-input
          id="input-2"
          v-model="form.department"
          required
          readonly
        ></b-form-input>
        </div>
      </div>

      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">No. Hp</label>
        <div class="col-sm-10">
          <b-form-input
          id="input-2"
          v-model="form.phone_number"
          required
          readonly
        ></b-form-input>
        </div>
      </div>

      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Email</label>
        <div class="col-sm-10">
          <b-form-input
          id="input-2"
          v-model="form.email"
          required
          readonly
        ></b-form-input>
        </div>
      </div>

      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Site</label>
        <div class="col-sm-4">
          <b-form-input
          id="input-3"
          v-model="form.site.name"
          required
          disabled
        ></b-form-input>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <b-button pill class="btn" size="sm" @click="toEdit()" variant="success">
          <b-icon icon="pencil-square"></b-icon>
        </b-button>
        <b-button pill class="btn" @click="onDelete()" size="sm" variant="danger">
          <b-icon icon="trash-fill"></b-icon>
        </b-button>
      </div>
    </b-form>
    
    <!-- <b-card class="mt-3" header="Form Data Result">
      <pre class="m-0">{{ form }}</pre>
    </b-card> -->
    </div>
  </div>
</template>

<script>
import axios from 'axios';
  export default {
    data() {
      return {
        users: {},
        form: {},
        show: true
      }
    },
    methods: {
      setUsers(data){
        this.form = data
      },
      toEdit(){
        this.$router.push({ path : '/editaccount/'+this.$route.params.id});
      },
      goBack(){
        this.$router.go(-1)
      },
      onDelete(){
          axios.delete('v1/users/'+this.$route.params.id)
          .catch(e=>{
              alert(e);
          })
          .then(response => {
              this.$router.push({ path : '/listaccount/'});
          })
      },
    },
    mounted() {
      axios.get('v1/users/'+this.$route.params.id+'?eagerLoad=true')
      .then(response => {
          this.setUsers(response.data['data']);
      });
    }
  }
</script>

<style scoped>
h3{
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.form-group{
  margin-top: 10px !important;
}

.d-flex{
  margin-top: 20px !important;
  margin-bottom: 30px !important;
}

.btn{
  margin-left: 10px !important;
  width: 70px !important;
}

.form_account{
  background-color: #ffff !important;
  padding: 35px !important;
  border-radius: 10px;
}

.cont h3 {
  font-weight: bold;
}

.cont {
  margin: 40px;
}

</style>
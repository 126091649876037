import Vue from 'vue'
import VueRouter from 'vue-router'
import CreateAccount from '../views/CreateAccount.vue'
import ListofAccount from '../views/ListofAccount.vue'
import FilterAccount from '../views/FilterAccount.vue'
import DetailAccount from '../views/DetailAccount.vue'
import EditAccount from '../views/EditAccount.vue'
import CreateSite from '../views/CreateSite.vue'
import ListofSite from '../views/ListofSite.vue'
import FilterSite from '../views/FilterSite.vue'
import EditSite from '../views/EditSite.vue'
import DetailSite from '../views/DetailSite.vue'
import InfoDashboard from '../components/InformationDashboard.vue'
import AddAsset from '../views/AddAsset.vue'
import Category from '../views/Category.vue'
import AssetList from '../views/AssetList.vue'
import AssetFilter from '../views/AssetFilter.vue'
import AssetDetail from '../views/AssetDetail.vue'
import ImportAsset from '../views/ImportAsset.vue'
import AssetEdit from '../views/AssetEdit.vue'
import Login from '../views/Login.vue'
import store from '../store/index.js'
import ResetPassword from '../views/ResetPassword.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import Logout from '../views/Logout.vue'
import RequestList from '../views/RequestList.vue'
import RequestDetail from '../views/DetailRequest.vue'
import RequestDetailDelete from '../views/DetailRequestDelete.vue'
import RequestDetailDocument from '../views/DetailRequestDocument.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: to => {
      if(store.getters.isLoggedIn && store.getters.user){
        return{
          path: '/dashboard',
          name: 'InfoDashboard',
          component: InfoDashboard,
          meta: {
            auth: true,
          }
        }
      } else {
        return {
          path: '/login',
          name: 'Login',
          component: Login,
          meta: {
            guest: true
          }
        }
      }
    }
  },
  {
    path: '/createaccount',
    name: 'CreateAccount',
    component: CreateAccount,
    meta: {
      permittedRoleMin: 2,
      auth: true,
    }
  },
  {
    path: '/listaccount/:type?/:search?/:site_id?/:sortOn?/:sortFrom?/:amount?',
    name: 'ListofAccount',
    component: ListofAccount,
    props: true,
    meta: {
      permittedRoleMin: 2,
      auth: true,
    }
  },
  {
    path: '/filteraccount',
    name: 'FilterAccount',
    component: FilterAccount,
    meta: {
      permittedRoleMin: 2,
      auth: true,
    }
  },
  {
    path: '/account/:id',
    name: 'DetailAccount',
    component: DetailAccount,
    meta: {
      permittedRoleMin: 2,
      auth: true,
    }
  },
  {
    path: '/editaccount/:id',
    name: 'EditAccount',
    component: EditAccount,
    meta: {
      permittedRoleMin: 2,
      auth: true,
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      auth: true,
    }
  },
  {
    path: '/resetpassword',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/createsite',
    name: 'CreateSite',
    component: CreateSite,
    meta: {
      permittedRoleMin: 2,
      auth: true,
    }
  },
  {
    path: '/listsite/:search?/:sortOn?/:sortFrom?/:amount?',
    name: 'ListSite',
    component: ListofSite,
    props: true,
    meta: {
      auth: true,
    }
  },
  {
    path: '/filtersite',
    name: 'FilterSite',
    component: FilterSite,
    meta: {
      auth: true,
    }
  },
  {
    path: '/editsite/:id',
    name: 'EditSite',
    component: EditSite,
    meta: {
      permittedRoleMin: 2,
      auth: true,
    }
  },
  {
    path: '/detailsite/:id',
    name: 'DetailSite',
    component: DetailSite,
    meta: {
      auth: true,
    }
  },
  {
    path: '/dashboard',
    name: 'InfoDashboard',
    component: InfoDashboard,
    meta: {
      auth: true,
    }
  },
  {
    path: '/createasset',
    name: 'AddAsset',
    component: AddAsset,
    meta: {
      permittedRoleMin: 1,
      auth: true,
    }
  },
  {
    path: '/category',
    name: 'Category',
    component: Category,
    meta: {
      auth: true,
    }
  },
  {
    path: '/asset-list/:type?/:search?/:site_id?/:sortOn?/:sortFrom?/:amount?/:valueRangeMin?/:valueRangeMax?/:dateRangeMin?/:dateRangeMax?',
    name: 'AssetList',
    component: AssetList,
    props: true,
    meta: {
      auth: true,
    }
  },
  {
    path: '/asset-filter',
    name: 'AssetFilter',
    component: AssetFilter,
    meta: {
      auth: true,
    }
  },
  {
    path: '/asset-detail/:id',
    name: 'AssetDetail',
    component: AssetDetail,
    props: true,
    meta: {
      auth: true,
    }
  },
  {
    path: '/import-asset',
    name: 'ImportAsset',
    component: ImportAsset,
    meta: {
      permittedRoleMin: 2,
      auth: true,
    }
  },
  {
    path: '/asset-edit/:id',
    name: 'AssetEdit',
    component: AssetEdit,
    props: true,
    meta: {
      permittedRoleMin: 1,
      auth: true,
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      guest: true
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
  },
  {
    path: '/request-list',
    name: 'RequestList',
    component: RequestList,
    meta: {
      permittedRoleMin: 2,
      auth: true,
    }
  },
  {
    path: '/request/:id',
    name: 'RequestDetail',
    component: RequestDetail,
    meta: {
      permittedRoleMin: 2,
      auth: true,
    }
  },
  {
    path: '/request-delete/:id',
    name: 'RequestDetailDelete',
    component: RequestDetailDelete,
    meta: {
      permittedRoleMin: 2,
      auth: true,
    }
  },
  {
    path: '/request-document/:id',
    name: 'RequestDetailDocument',
    component: RequestDetailDocument,
    meta: {
      permittedRoleMin: 2,
      auth: true,
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.permittedRoleMin)){
    if(store.getters.isLoggedIn && to.meta.permittedRoleMin <= store.getters.user.type){
      next()
      return
    }
    else next('/')
  }
  
  if(to.matched.some(record => record.meta.auth)){
    if(store.getters.isLoggedIn && store.getters.user){
      next()
      return
    }
    else next('/')
  }

  if(to.matched.some(record => record.meta.guest)){
    if(!store.getters.isLoggedIn){
      next()
      return
    }
    else next('/')
  }

  next()
})


export default router

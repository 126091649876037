<template>
    <div class="container">
        <h1>Dashboard</h1>
        <information-container :currTabId="currTabId"/>
    </div>
</template>

<script>
import LineChart from './LineChart.vue'
import PieChart from './PieChart.vue'
import InformationContainer from './InformationContainer.vue'

export default {
  name: 'InformationDashboard',
  components: {
    LineChart,
    PieChart,
    InformationContainer
  },
  data(){
    return{
      currTabId : "tanah"
    }
  }
}
</script>

<style scoped >
h1 {
  text-align: center !important;
  font-weight: bold;
}
/* #app {
  font-family: Montserrat, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
} */
</style>
import { render, staticRenderFns } from "./DetailSite.vue?vue&type=template&id=438b1f06&scoped=true&"
import script from "./DetailSite.vue?vue&type=script&lang=js&"
export * from "./DetailSite.vue?vue&type=script&lang=js&"
import style0 from "./DetailSite.vue?vue&type=style&index=0&id=438b1f06&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "438b1f06",
  null
  
)

export default component.exports
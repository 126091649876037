<template>
  <div class="container">
    <b-row>
      <h3>Edit Aset</h3>
    </b-row>
    <b-form @submit="Save" enctype="multipart/form-data" novalidate>
    
    <div class="white-panel">
      <!--Head-->
      <b-row>
        <h4>{{this.form.asset_name}}</h4>    
      </b-row>

      <b-row>
        <b-col>
            <b-row>
              <div class="photo-container">
                  <b-img v-if="this.form.photo_url" class="photo" :src="this.endpoint + this.form.photo_url" fluid></b-img>
              </div>

              <b-col xl="9">
                <div class="row">
              <!--Kolom Kiri-->
              <div class="col-lg-6">

                <!--Pilih Gambar-->
                <div class="form-group row">
                  <label for="image" class="col-sm-5 col-form-label">Pilih Gambar</label>
                  <div class="col-sm-7">
                    <input type="file" class="form-control" v-on:change="onFileChange" accept="image/*">
                    <p v-if="failed.file" class="failed-text font-montserrat">{{error_message.file}}</p>
                  </div>
                </div>
                
                <!--Nama-->
                <div class="form-group row">
                  <label for="input-name" class="col-sm-5 col-form-label">Nama</label>
                  <div class="col-sm-7">
                    <b-form-input
                      id="input-name"
                      v-model="form.asset_name"
                      required
                      size="sm"
                    ></b-form-input>
                    <p v-if="failed.asset_name" class="failed-text font-montserrat">{{error_message.asset_name}}</p>
                  </div>
                </div>
                

                <!--Tanggal Perolehan-->
                <div class="form-group row">
                  <label for="datepicker" class="col-sm-5 col-form-label">Tanggal Perolehan</label>
                  <div class="col-sm-7">
                    <b-form-datepicker
                      id="datepicker"
                      v-model="form.purchase_date"
                      size="sm"
                    ></b-form-datepicker>
                    <p v-if="failed.purchase_date" class="failed-text font-montserrat">{{error_message.purchase_date}}</p>
                  </div>
                </div>

                <!--Site-->
                <div>
                  <div class="form-group row">
                    <label for="input-site" class="col-sm-5 col-form-label">Site</label>
                    <div class="col-sm-7">
                      <b-form-select
                        id="input-site"
                        v-model="form.site_id"
                        required
                        size="sm"
                        style="width: 100%; height: 100%"
                      ><b-form-select-option v-for="item in this.sites" :value="item.id">{{item.name}}</b-form-select-option>
                      </b-form-select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-4"></div>
                    <div class="col-sm-8 m-1">
                      <p v-if="failed.site_id" class="failed-text font-montserrat">{{error_message.site_id}}</p>
                    </div>
                  </div>
                </div>

              </div>

              <!--Kolom Kanan-->
              <div class="col-lg-6">

                <!--Harga Individu-->
                <div class="form-group row">
                  <label for="input-costIndv" class="col-sm-4 col-form-label">Harga Per Unit</label>
                  <div class="col-sm-8">
                    <b-form-input
                      id="input-costIndv"
                      v-model="form.cost_individual"
                      required
                      type="number"
                      size="sm"
                    ></b-form-input>
                    <p v-if="failed.cost_individual" class="failed-text font-montserrat">{{error_message.cost_individual}}</p>
                  </div>
                </div>

                <!--Unit-->
                <div class="form-group row">
                  <label for="unit" class="col-sm-4 col-form-label">Jumlah Unit</label>
                  <div class="col-sm-8">
                    <b-form-input
                      id="unit"
                      v-model="form.unit"
                      required
                      type="number"
                      size="sm"
                    ></b-form-input>
                    <p v-if="failed.unit" class="failed-text font-montserrat">{{error_message.unit}}</p>
                  </div>
                </div>

                <!--Cost Total-->
                <div class="form-group row">
                  <label for="totalCost" class="col-sm-4 col-form-label">Total Harga</label>
                  <div class="col-sm-8">
                    <b-form-input
                      id="totalCost"
                      v-model="form.cost_total"
                      required
                      type="number"
                      size="sm"
                    ></b-form-input>
                    <p v-if="failed.cost_total" class="failed-text font-montserrat">{{error_message.cost_total}}</p>
                  </div>
                </div>

                <!--Apakah Depreciable-->
                <div class="form-group row">
                  <label for="depreciable" class="col-sm-4 col-form-label">Depreciable</label>
                  <div class="col-sm-8">
                    <input type="checkbox"
                      id="depreciable" 
                      v-model="form.is_depreciable"
                      value=1
                      unchecked-value=0
                      style="width: 8%; height: 100%"
                      >
                  </div>
                </div>

              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>

    <detail-container :form="form" :documents="documents" :gallery="gallery" :categories="categories" :isEdit="true" :failed="failed" :error_message="error_message"/>
      <!--Buttons-->
      <div class="d-flex justify-content-end">
          <b-button pill class="btn" type="submit" variant="success">
            <b-icon icon="check2"></b-icon>
          </b-button>
          <b-button pill class="btn" :to="{path:'/asset-list'}" variant="danger">
            <b-icon icon="arrow-left"></b-icon>
          </b-button>
      </div>

    </b-form>

      <b-modal ref="success-modal" class="font-montserrat" hide-footer hide-header no-close-on-backdrop no-close-on-esc hide-header-close>
        <div>
          <h3 class="font-montserrat">{{success_message}}</h3>
        </div>
        <b-button class="mt-3 font-montserrat" variant="outline-success" block @click="closeModal">Oke</b-button>
      </b-modal>

  </div>
 
  
</template>

<script>
  import DetailContainer from '../components/DetailContainer.vue'
  import axios from "axios"
  import {mapGetters} from 'vuex'

  export default {
    props: ['id'],
    data() {
      return {
        form: {},
        show: true,
        sites: null,
        categories: null,
        documents: null,
        gallery: null,
        types: null,
        endpoint: process.env.VUE_APP_ROOT_API,
        failed: {
          // Umum
          asset_name          : false,
          type                : false,
          purchase_date       : false,
          file                : false,
          site_id             : false,
          cost_individual     : false,
          unit                : false,
          cost_total          : false,
          is_depreciable      : false,
          depreciation_ratio  : false,
          depreciation_start_date : false,
          // Tanah
          street              : false,
          rt                  : false,
          rw                  : false,
          village             : false,
          district            : false,
          city                : false,
          province            : false,
          persil_number       : false,
          certificate_number  : false,
          nib_number          : false,
          width               : false,
          description         : false,
          // Bangunan
          address             : false,
          num_of_floor        : false,
          building_area       : false,
          build_year          : false,
          usage_year          : false,
          added_benefit_value : false,
          // Kendaraan
          machine_number      : false,
          manufacture_year    : false,
          body_number         : false,
          cc                  : false,
          brand               : false, // Furnitur juga
          num_tires           : false,
          bpkb_number         : false,
          fund_source         : false,
          in_charge           : false, // Furnitur juga
          // Furnitur dan Elektronik
          category_id         : false,
          source              : false,
        },
        error_message: {
          // Umum
          asset_name          : '',
          type                : '',
          purchase_date       : '',
          file                : '',
          site_id             : '',
          cost_individual     : '',
          unit                : '',
          cost_total          : '',
          is_depreciable      : '',
          depreciation_ratio  : '',
          depreciation_start_date : '',
          // Tanah
          street              : '',
          rt                  : '',
          rw                  : '',
          village             : '',
          district            : '',
          city                : '',
          province            : '',
          persil_number       : '',
          certificate_number  : '',
          nib_number          : '',
          width               : '',
          description         : '',
          // Bangunan
          address             : '',
          num_of_floors        : '',
          building_area       : '',
          build_year          : '',
          usage_year          : '',
          added_benefit_value : '',
          // Kendaraan
          machine_number      : '',
          manufacture_year    : '',
          body_number         : '',
          cc                  : '',
          brand               : '', // Furnitur juga
          num_tires           : '',
          bpkb_number         : '',
          police_number       : '',
          fund_source         : '',
          in_charge           : '', // Furnitur juga
          // Furnitur dan Elektronik
          category_id         : '',
          source              : '',
        },
        success_message: '',
        fileChanged: false
      }
    },
    components:{
      DetailContainer
    },
    methods: {
        onFileChange(e){
          this.form.file = e.target.files[0];
          this.fileChanged = true;
        },
        GetData() {
            var link = 'v1/assets/' + this.id.toString() + '?eagerLoad=true'
            axios
            .get(link)
            .then(response => {this.form = response.data.data})
            .catch (error => {console.log(error)})
        },
        GetDocuments() {
          this.documents = []
          var link = 'v1/assets/' + this.id.toString() + '/documents'
          axios
          .get(link)
          .then(response => {this.documents = response.data.data})
          .catch (error => {console.log(error)})
        },
        GetGallery() {
          this.gallery = []
          var link = 'v1/assets/' + this.id.toString() + '/galleries'
          axios
          .get(link)
          .then(response => {this.gallery = response.data.data})
          .catch (error => {console.log(error)})
        },
        GetSites() {
          this.sites = []
          axios
          .get('v1/sites')
          .then(response => {this.sites = response.data.data})
          .catch (error => {console.log(error)})
        },
        GetCategories() {
          this.categories = []
          axios
          .get('v1/categories')
          .then(response => {this.categories = response.data.data})
          .catch (error => {console.log(error)})
        },
        Save(e) {
          Object.keys(this.failed).forEach(k => this.failed[k] = false);
          Object.keys(this.error_message).forEach(k => this.error_message[k] = "");
          e.preventDefault();
          let currentObj = this;

          const config = {
              headers: { 'content-type': 'multipart/form-data' }
          }
          
          let submitArray = new FormData()

          submitArray.append('type', this.form.type)
          submitArray.append('asset_name', this.form.asset_name)
          submitArray.append('unit', this.form.unit)
          submitArray.append('site_id', this.form.site_id)
          submitArray.append('cost_individual', this.form.cost_individual)
          submitArray.append('cost_total', this.form.cost_total)
          submitArray.append('purchase_date', this.form.purchase_date)
          if (this.form.is_depreciable) {
            submitArray.append('is_depreciable', 1)
          } else {
            submitArray.append('is_depreciable', 0)
          }
          submitArray.append('depreciation_ratio', this.form.depreciation_ratio)
          submitArray.append('depreciation_start_date', this.form.depreciation_start_date)

          if (this.fileChanged) {
            submitArray.append('file', this.form.file)
          }

          // Tanah
          if (this.form.type === 'Land') {
              submitArray.append('assetable[street]', this.form.assetable.street)
              submitArray.append('assetable[number]', this.form.assetable.number)
              submitArray.append('assetable[rt]', this.form.assetable.rt)
              submitArray.append('assetable[rw]', this.form.assetable.rw)
              submitArray.append('assetable[village]', this.form.assetable.village)
              submitArray.append('assetable[district]', this.form.assetable.district)
              submitArray.append('assetable[city]', this.form.assetable.city)
              submitArray.append('assetable[province]', this.form.assetable.province)
              submitArray.append('assetable[persil_number]', this.form.assetable.persil_number)
              submitArray.append('assetable[certificate_number]', this.form.assetable.certificate_number)
              submitArray.append('assetable[nib_number]', this.form.assetable.nib_number)
              submitArray.append('assetable[width]', this.form.assetable.width)
              submitArray.append('assetable[description]', this.form.assetable.description)
          }
          else if (this.form.type === 'Building') {
              submitArray.append('assetable[address]', this.form.assetable.address)
              submitArray.append('assetable[building_area]', this.form.assetable.building_area)
              submitArray.append('assetable[num_of_floors]', this.form.assetable.num_of_floors)
              submitArray.append('assetable[build_year]', this.form.assetable.build_year)
              submitArray.append('assetable[usage_year]', this.form.assetable.usage_year)
              submitArray.append('assetable[added_benefit_value]', this.form.assetable.added_benefit_value)
          }
          else if (this.form.type === 'Vehicle') {
              submitArray.append('assetable[brand]', this.form.assetable.brand)
              submitArray.append('assetable[machine_number]', this.form.assetable.machine_number)
              submitArray.append('assetable[body_number]', this.form.assetable.body_number)
              submitArray.append('assetable[cc]', this.form.assetable.cc)
              submitArray.append('assetable[manufacture_year]', this.form.assetable.manufacture_year)
              submitArray.append('assetable[num_tires]', this.form.assetable.num_tires)
              submitArray.append('assetable[bpkb_number]', this.form.assetable.bpkb_number)
              submitArray.append('assetable[police_number]', this.form.assetable.police_number)
              submitArray.append('assetable[fund_source]', this.form.assetable.fund_source)
              submitArray.append('assetable[in_charge]', this.form.assetable.in_charge)
          }
          else if (this.form.type === 'Furniture and Electronics') {
              submitArray.append('assetable[category_id]', this.form.assetable.category.id)
              submitArray.append('assetable[brand]', this.form.assetable.brand)
              submitArray.append('assetable[source]', this.form.assetable.source)
              submitArray.append('assetable[in_charge]', this.form.assetable.in_charge)
          }

          submitArray.append('_method', 'PUT')
          
          var link = 'v1/assets/' + this.id.toString()
          axios
          .post(link, submitArray, config)
          .then(response => {
            this.showModal();
          })
          .catch (error => {
            // Terjemahan kolom
            const columnTranslated = {
                // Umum
              "asset_name"          : "nama aset",
              "type"                : "kategori aset",
              "purchase_date"       : "tanggal pembelian",
              "file"                : "file foto",
              "site_id"             : "site",
              "cost_individual"     : "harga per unit",
              "unit"                : "jumlah unit",
              "cost_total"          : "total harga",
              "is_depreciable"      : "depreciable",
              "depreciation_ratio"  : "rasio depresiasi",
              "depreciation_start_date" : "tanggal mulai depresiasi",
              // Tanah
              "street"              : "jalan",
              "rt"                  : "RT",
              "rw"                  : "RW",
              "village"             : "desa/kelurahan",
              "district"            : "kecamatan",
              "city"                : "kota/kabupaten",
              "province"            : "provinsi",
              "persil_number"       : "nomor persil",
              "certificate_number"  : "nomor sertifikat",
              "nib_number"          : "NIB",
              "width"               : "luas tanah",
              "description"         : "deskripsi",
              // Bangunan
              "address"             : "alamat",
              "num_of_floors"        : "banyak lantai",
              "building_area"       : "luas bangunan",
              "build_year"          : "tahun dibangun",
              "usage_year"          : "tahun penggunaan",
              "added_benefit_value" : "added benefit value",
              // Kendaraan
              "machine_number"      : "nomor mesin",
              "manufacture_year"    : "tahun pembuatan",
              "body_number"         : "nomor rangka",
              "cc"                  : "isi silinder",
              "brand"               : "jenis/merk", // Furnitur juga
              "num_tires"           : "jumlah roda",
              "bpkb_number"         : "nomor BPKB",
              "fund_source"         : "sumber pendanaan",
              "in_charge"           : "tanggung jawab", // Furnitur juga
              // Furnitur dan Elektronik
              "category_id"         : "kategori",
              "source"              : "sumber",
            }

            // Cek apakah ada masalah di suatu kolom
            Object.keys(this.failed).forEach( (key) => {
              var exists = false;
              var assetableKey = key;
              if(error.response.data.error.errors.hasOwnProperty(key)){
                exists = true;
              } else if(error.response.data.error.errors.hasOwnProperty("assetable." + key)){
                exists = true;
                assetableKey = "assetable." + key;
              }

              if(exists){
                this.failed[key] = true;
                this.error_message[key] = "Kolom " + columnTranslated[key] + " tidak boleh kosong";

                if(error.response.data.error.errors[assetableKey].includes("The " + this.ChangeToWords(assetableKey) + " must be an integer.")){
                  this.error_message[key] = columnTranslated[key] + " harus merupakan bilangan bulat";
                }

                if(error.response.data.error.errors[assetableKey].includes("The " + this.ChangeToWords(assetableKey) + " must not be greater than 255 characters.")){
                  this.error_message[key] = columnTranslated[key] + " tidak boleh lebih dari 255 karakter";
                }

                if(error.response.data.error.errors[assetableKey].includes("The " + this.ChangeToWords(assetableKey) + " must be at least 0.")){
                  this.error_message[key] =  columnTranslated[key] + " harus bernilai minimal 0";
                } 
                
                if(error.response.data.error.errors[assetableKey].includes("The " + this.ChangeToWords(assetableKey) + " must be at least 1.")){
                  this.error_message[key] =  columnTranslated[key] + " harus bernilai minimal 1";
                }

                if(error.response.data.error.errors[assetableKey].includes("The file must be a file of type: jpeg, png, jpg, gif, svg.")){
                  this.error_message[key] = columnTranslated[key] + " hanya bisa berupa jpeg, png, jpg, gif, atau svg"
                }

              }
            })

            // Bagian yang belum tercover BE
            const unnullable = ["site_id", "cost_individual", "cost_total"];
            const nonnegative = ["num_of_floors", "building_area", "num_tires", "police_number"];
            const positive = ["depreciation_ratio"];

            unnullable.forEach((column) => {
              if(this.form[column] === null){
                this.failed[column] = true;
                this.error_message[column] = columnTranslated[column] + " tidak boleh kosong";
              }
            })

            nonnegative.forEach((column) => {
              if(this.form[column] !== null){
                if(this.form[column] < 0){
                  this.failed[column] = true;
                  this.error_message[column] = columnTranslated[column] + " tidak boleh bernilai negatif";
                }
              }
            })

            positive.forEach((column) => {
              if(this.form[column] !== null){
                if(this.form[column] <= 0){
                  this.failed[column] = true;
                  this.error_message[column] = columnTranslated[column] + " harus bernilai positif";
                }
              }
            })
          })
        },
        ChangeToWords(word){
          return word.replace("_", " ");        
        },
        showModal(){
          if(this.user.type === 1){
            this.success_message = "Request pengeditan aset berhasil dibuat";
          } else if(this.user.type === 2){
            this.success_message = "Aset berhasil diedit";
          }
          this.$refs["success-modal"].show();
        },
        hideModal(){
          this.$refs["success-modal"].hide();
        },
        closeModal(){
          this.hideModal();
          this.$router.push({ path : '/asset-list/' });
        },
      },
    mounted() {
      this.GetData()
      this.GetDocuments()
      this.GetGallery()
      this.GetSites()
      this.GetCategories()
    },
    computed: {
      ...mapGetters({
        isLoggedIn: 'isLoggedIn',
        user: 'user',
      })
    }
  }
</script>

<style scoped>
p {
  font-size: 14xp;
  margin-bottom: 10px;
  font: "Montserrat";
}
h3 {
  font: "Montserrat";
  font-weight: bold;
}
h4 {
  font: "Montserrat";
  font-weight: bold;
}
.d-flex{
  margin-top: 20px;
  margin-bottom: 30px;
}
.form-group{
  margin-top: 10px !important;
}
.btn{
  margin-left: 10px !important;
  width: 70px !important
}
.photo-container{
    width: 196px;
    height: 196px;
    background: black;
    margin-right: 20px !important;
    padding: 10px;
}
.photo{
    width: 174px;
    height: 172px;
}
body {
  display: flex !important ;
  flex-direction: column !important ;
   background: #F1F1F1 !important ;
}
.white-panel {
  margin-top: 20px;
  background: white !important ;
  padding: 20px 40px 20px 40px !important;
  border-radius: 10px !important;
}
.container {
  font-size: 12px !important
}

.failed-text{
  font-size: 14px;
  margin-top: 10px;
        color: red;
        text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

.font-montserrat{
  font-family: "Montserrat";
}

p::first-letter{
  text-transform: capitalize;
}

</style>
